import { createMachine, assign } from "xstate";
import axios from "axios";
import getUrlParam, { getDevice, getValueCookie, addValueCookie, getTrace, getState, searchParamUrl } from "../Utilities/httpUtils";
import { encryptAuth } from "../Services/bcOaep";
import {
  COOKIE_NAME,
  CREDENTIALS_CODES_ERROS,
  MINUS_ONE,
  UNAVAILABILITY_CODES_ERROS,
  contentType,
  scoreRecaptchaCodeErrror,
  unknowErrror,
  informationScreen,
  informationNavigator,
  accesBlocked,
  failedAttempts,
  cookieNameCsid,
  referrerMatchAppAuth
} from "../Constant/Constants";
import config from "../config";
import { listCodeErroDynamicKey, codeDynamicKeyIncorrect } from "../Constant/ConstantsDynamicKey";
import { customerFlow } from "../Services/CustomerFlow";
import { modelAuthV1, modelAuthV2 } from "../Model/ModelAuth"
import { behavioralAnalysis } from "../Services/BehavioralAnalysis";
import { validateRedirect } from "../Utilities/GeneralFunctions"

const URLAUTHORIZE = config.url.URLAUTHORIZE;
const URLSSO = config.url.URLSSO;
const URLGETIP = config.url.URLGETIP;
const URLPREPAREDYNAMICKEY = config.url.URLPREPAREDYNAMICKEY
const URLQUERYATTEMPS = config.url.URLQUERYATTEMPS;
const userAllowedAttempts = 3;
const timeExpiration = 1000;
let onlyPinFlow = true;
const URLVALIDATEDYNAMICKEY = config.url.URLVALIDATEDYNAMICKEY

const actions = {
  setUsername: assign({ username: (_context, event) => event.username }),
  setRecaptcha: assign({ recapvisible: (_context, event) => event.data.attempts > 0 }),
  setRecaptchaDefault: assign({ recapvisible: _context => true })
};

const conditions = {
  isRecaptchaV2: _context => _context.startUrl.includes("v2"),
  successAttemps: (_context, event) => event.data.attempts !== undefined && event.data.attempts !== null
};

const fetchValidateParameters = async () => {
  const ip = await fetch(URLGETIP).then(response => response.json());
  return {
    currentIp: ip,
    idClient: getUrlParam("client_id"),
    responseType: getUrlParam("response_type"),
    redirectUri: getUrlParam("redirect_uri"),
    trace: getUrlParam("trace"),
    prompt: getUrlParam("prompt"),
    linkCode: getUrlParam("linkCode"),
    businessPartner: getUrlParam("businessPartner"),
    startUrl: document.URL,
    state: getUrlParam("state"),
  };
};

const validateCookieService = () => async () => {
  const valueCookie = await getValueCookie(COOKIE_NAME)
  if (valueCookie === undefined) {
    return null;
  }
  return valueCookie;
};

const getBodyUrlauthorize = async context => {
  return {
    data: {
      data: [
        {
          type: "validation",
          id: context.idClient,
          attributes: {
            responseType: context.responseType,
            redirectUri: context.redirectUri,
            ipConsumidor: context.currentIp.ip,
            trace: context.trace,
            prompt: context.prompt,
            linkCode: context.linkCode,
            businessPartner: context.businessPartner,
          },
        },
      ],
    },
  };
}

const authorize = async context => {
  const PARAMS = await getBodyUrlauthorize(context);
  if (context.idClient !== "" && context.idClient !== null) {
    const headersAuthorize = { contentType };
    return axios
      .post(URLAUTHORIZE, PARAMS.data, { headersAuthorize })
      .then(response => response.data);
  }
  throw new Error("clientId no especificado");
};

const validatePrompt = async context => {
  if (context.prompt !== null && typeof context.prompt === "string") {
    context.prompt = context.prompt.replace(context.idClient + "-", "").split("|")
    onlyPinFlow = context.prompt.length === 1 && context.prompt[0] === "PN" ? true : false;
  }
}

const getDataValidateAuthFua = async (context, event) => {
  const { username, password, captchaToken } = event;
  const pinblockRsa = await encryptAuth(username, password, "usuario");
  await validatePrompt(context);
  const device = await getDevice()
  if (context.startUrl.includes("v2")) {
    return modelAuthV2({ context: context, username: username, encrptedPassword: pinblockRsa, deviceType: device, captchaToken: captchaToken, csid: await getValueCookie(cookieNameCsid) })
  } else {
    return modelAuthV1({ context: context, username: username, encrptedPassword: pinblockRsa, deviceType: device, captchaToken: captchaToken })
  }
}

const validateAuthFua = async (context, event) => {
  const PARAMS = await getDataValidateAuthFua(context, event);
  const headersValidateAuthFua = { contentType };
  return axios
    .post(PARAMS.data[1].url, PARAMS.data[0], { headersValidateAuthFua })
    .then(response => response.data);
};

const setCookie = (cvalue, exTime) => {
  const expires = exTime * timeExpiration
  addValueCookie(COOKIE_NAME, expires, cvalue)
};

const deleteCookie = async () => {
  document.cookie = COOKIE_NAME + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

const getBodySingleSingOn = async context => {
  return {
    clientId: context.idClient,
    sso_id: context.sso_id,
    ip: context.currentIp.ip,
    info: {
      screen: informationScreen,
      navigator: informationNavigator,
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  }
}

const singleSingOnService = async context => {
  const request = await getBodySingleSingOn(context);
  const res = await axios
    .post(URLSSO, request, { contentType })
    .then(response => response);

  if (res.data.errors && res.data.errors[0].title === "SSO invalido") {
    deleteCookie()
  }
  return res
};

const prepareDynamicKeyFunction = async (context, event) => {
  const { url } = event.data
  const codeAuthorization = searchParamUrl(url, "code")
  const request = {
    code: codeAuthorization,
    consumerId: context.idClient
  }
  return axios
    .post(URLPREPAREDYNAMICKEY, request, { contentType })
    .then(response => response.data);
};

const validateRecaptcha = async context => {
  const request = await getBodyValidateRecaptcha(context);
  const headersValidateAuthFua = { contentType };
  return axios
    .post(URLQUERYATTEMPS, request, { headersValidateAuthFua })
    .then(response => response.data);
};

const getBodyValidateRecaptcha = async context => {
  return {
    data: [{
      trace: context.trace,
      username: context.username,
      documentType: "",
      documentNumber: ""
    }]
  }
}

const validateDynamicKeySf = async (context, event) => {
  const { otp } = event
  const codeAuthorization = searchParamUrl(context.urlCode, "code")
  const request = {
    code: codeAuthorization,
    channel: context.idClient,
    otp: otp,
    state: context.state
  }
  return await axios
    .post(URLVALIDATEDYNAMICKEY, request, { contentType })
    .then(response => response.data);
};

const initialContext = {
  status: "",
  error: "",
  currentIp: "",
  idClient: "",
  responseType: "",
  redirectUri: "",
  redirectUrl: "",
  auth: "",
  attemps: 0,
  modulus: "",
  exponent: "",
  trace: "",
  sso_id: "",
  errorCode: "",
  urlCode: "",
  otp: "",
  prompt: "",
  linkCode: "",
  businessPartner: "",
  errorCodeDynamicKey: "",
  startUrl: "",
  deviceType: "",
  timeFailedAttempts: "",
  recapvisible: true,
  username: "",
  state: ""
};

const fuaMachine = createMachine(
  {
    id: "fua",
    initial: "validateRedirect",
    context: initialContext,
    states: {
      validateRedirect: {
        invoke: {
          id: "validateRedirectId",
          src: validateRedirect,
          onDone: [
            {
              cond: (_context, event) => event.data.redirectUrl,
              actions: assign({ redirectUrl: (_context, event) => event.data.redirectUrl }),
              target: "redirect",
            },
            {
              target: "validateParameters",
            },
          ],
          onError: {
            target: "errorForm",
          },
        },
      },
      validateParameters: {
        invoke: {
          id: "validateParametersId",
          src: fetchValidateParameters,
          onDone: {
            target: "validateCookie",
            actions: assign({
              currentIp: (_context, event) => event.data.currentIp,
              idClient: (_context, event) => event.data.idClient,
              responseType: (_context, event) => event.data.responseType,
              redirectUri: (_context, event) => event.data.redirectUri,
              trace: (_context, event) => event.data.trace,
              prompt: (_context, event) => event.data.prompt,
              linkCode: (_context, event) => event.data.linkCode,
              businessPartner: (_context, event) => event.data.businessPartner,
              startUrl: (_context, event) => event.data.startUrl,
              state: (_context, event) => event.data.state,
            }),
          },
          onError: {
            target: "errorForm",
          },
        },
      },
      validateCookie: {
        invoke: {
          id: "checkCookieId",
          src: validateCookieService,
          onDone: [
            {
              cond: (_context, event) =>
                event.data !== "" && event.data !== null,
              target: "validCookie",
              actions: assign({
                sso_id: (_context, event) => event.data,
              }),
            },
            { target: "validateUrl" },
          ],
        },
      },
      validCookie: {
        invoke: {
          id: "singleSingOnId",
          src: singleSingOnService,
          onDone: [
            {
              cond: (_context, event) =>
                event.data.data.errors && event.data.data.errors != null,
              target: "validateUrl",
            },
            {
              cond: (_context, event) =>
                event.data.data && event.data.data.data != null,
              target: "successSSO",
              actions: ["redirectSSO"],
            },
          ],
        },
      },
      validateUrl: {
        invoke: {
          id: "validateUrlId",
          src: authorize,
          onError: {
            target: "errorForm",
            actions: assign({
              error: (_context, event) => event.data,
            }),
          },
          onDone: [
            {
              target: "errorForm",
              cond: (_context, event) => event.data.errors,
              actions: assign({
                errorCode: (_context, event) => event.data.errors[0].code,
              }),
            },
            {
              target: ["sendCredentials"],
              actions: assign({
                status: (_context, event) => event.data,
                modulus: (_context, event) =>
                  event.data.data[0].attributes.modulus,
              }),
            },
          ],
        },
      },
      sendCredentials: {
        initial: "checkRecaptcha",
        states: {
          checkRecaptcha: {
            on: {
              RETRY: [
                { cond: conditions.isRecaptchaV2, target: "validateRecaptcha", actions: actions.setUsername },
                { target: "continueFlow" },
              ],
            }
          },
          validateRecaptcha: {
            invoke: {
              id: "validateRecaptchaId",
              src: validateRecaptcha,
              onDone: [
                { cond: conditions.successAttemps, target: "continueFlow", actions: actions.setRecaptcha },
                { target: "continueFlow", actions: actions.setRecaptchaDefault }
              ],
              onError: { target: "continueFlow", actions: actions.setRecaptchaDefault }
            },
          },
          continueFlow: {
            after: {
              1: { target: "validateAttemps" },
            }
          },
          validateAttemps: {
            on: {
              VALIDATE: [
                {
                  target: "authFua",
                  cond: _context => _context.attemps < userAllowedAttempts,
                },
                { target: "userBlock" },
              ],
              RETRY: [
                { cond: conditions.isRecaptchaV2, target: "validateRecaptcha", actions: actions.setUsername },
                { target: "continueFlow" }
              ],
            },
          },
          authFua: {
            invoke: {
              id: "validateAuthFua",
              src: validateAuthFua,
              onDone: [
                {
                  cond: (_context, event) => event.data.errors && event.data.errors[0].code === "0607",
                  target: "userBlock",
                },
                {
                  cond: (_context, event) => event.data.errors && event.data.errors[0].code === accesBlocked,
                  target: "accesBlocked",
                },
                {
                  cond: (_context, event) => event.data.errors && CREDENTIALS_CODES_ERROS.indexOf(event.data.errors[0].code) !== MINUS_ONE,
                  target: "error",
                  actions: assign({ auth: (_context, event) => event.data.errors[0].code }),
                },
                {
                  cond: (_context, event) =>
                    event.data.errors &&
                    UNAVAILABILITY_CODES_ERROS.indexOf(
                      event.data.errors[0].code
                    ) !== MINUS_ONE,
                  target: "errorForm",
                  actions: assign({
                    auth: (_context, event) => event.data.errors[0].code,
                  }),
                },
                {
                  cond: (_context, event) => event.data.errors && event.data.errors[0].code === unknowErrror,
                  target: "unknowErrror",
                  actions: assign({
                    errorCode: (_context, event) => event.data.errors[0].code,
                    auth: (_context, event) => event.data.errors[0].code
                  }),
                },
                {
                  cond: (_context, event) => event.data.errors && event.data.errors[0].code === scoreRecaptchaCodeErrror,
                  target: "errorScore",
                  actions: assign({
                    auth: (_context, event) => event.data.errors[0].code,
                  }),
                },
                {
                  cond: (_context, event) => event.data.errors && event.data.errors[0].code === failedAttempts,
                  target: "failedAttempts",
                  actions: assign({
                    auth: (_context, event) => event.data.errors[0].code,
                    timeFailedAttempts: (_context, event) => event.data.errors[0].title,
                  }),
                },
                {
                  cond: (_context, event) => event.data.error === false && onlyPinFlow && event.data.factors.PN === true,
                  target: "success",
                  actions: ["redirectUrlAction"],
                },
                {
                  cond: (_context, event) => event.data.error === false && event.data.factors.hasOwnProperty("CD") && event.data.factors.PN === true,
                  target: "prepareDynamicKey",
                  actions: assign({
                    urlCode: (_context, event) => event.data.url
                  }),
                },
              ],
              onError: {
                target: "errorForm",
                actions: assign({ auth: (_context, event) => event.data }),
              },
            },
          },
          prepareDynamicKey: {
            invoke: {
              id: "prepareDynamicKeyId",
              src: prepareDynamicKeyFunction,
              onDone: [
                {
                  cond: (_context, event) => event.data.hasOwnProperty("errors") === false,
                  target: "validateDynamicKeySecondFactor",
                  actions: assign({
                    otp: (_context, event) => event.data.data.resultCode
                  }),
                },
                {
                  cond: (_context, event) => event.data.hasOwnProperty("errors") === true && listCodeErroDynamicKey.indexOf(event.data.errors[0].code) !== MINUS_ONE,
                  target: "errorPrepareDynamicKeyForm",
                  actions: assign({ errorCodeDynamicKey: (_context, event) => event.data.errors[0].code }),
                },
              ],
              onError: {
                target: "errorPrepareDynamicKey",
                actions: assign({ errorCodeDynamicKey: (_context, event) => event.data }),
              },
            }
          },
          validateDynamicKeySecondFactor: {
            initial: "validateDynamicKeyInitial",
            states: {
              validateDynamicKeyInitial: {
                on: {
                  VALIDATEDYNAMICKEYSF: [
                    {
                      target: "validateDynamicKeyDataSf",
                    }
                  ],
                },
              },

              validateDynamicKeyDataSf: {
                invoke: {
                  id: "validateAuthDataTokenSf",
                  src: validateDynamicKeySf,
                  onError: [
                    {
                      target: "errorService",
                      actions: assign({ errorCode: (_context, event) => event.data }),
                    }
                  ],
                  onDone: [
                    {
                      cond: (_context, event) => event.data.errors && codeDynamicKeyIncorrect.includes(event.data.errors[0].code),
                      target: "errorDynamicKeyIncorrect",
                      actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                    },
                    {
                      cond: (_context, event) => event.data.errors && UNAVAILABILITY_CODES_ERROS.includes(event.data.errors[0].code),
                      target: "errorUnavailability",
                      actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                    },
                    {
                      cond: (_context, event) => event.data.errors && listCodeErroDynamicKey.includes(event.data.errors[0].code),
                      target: "errorDynamicKey",
                      actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                    },
                    {
                      target: "successDynamicKey",
                      actions: ["redirectDynamicKeySf"],
                    },
                  ]
                }
              },
              errorService: {
                type: "final",
              },
              errorDynamicKeyIncorrect: {
                on: {
                  RETRYSENDDYNAMICKEY: {
                    target: "validateDynamicKeyInitial",
                  },
                },
              },
              errorUnavailability: {
                type: "final",
              },
              errorDynamicKey: {
                type: "final",
              },
              successDynamicKey: {
                type: "final",
                entry: ["redirectToTarget"],
              },

            },
          },
          success: {
            after: {
              2000: {
                target: "redirect",
                type: "final",
              },
            },
          },
          successPrepareDynamicKey: {
            on: {
              RETRY: { target: "continueFlow" }
            },
          },
          redirect: {
            type: "final",
            entry: ["redirectToTarget"],
          },
          userBlock: {
            on: {
              RETRYUSER: {
                target: "checkRecaptcha",
              },
            },
          },
          error: {
            entry: assign({
              attemps: context => context.attemps + 1,
            }),
            on: {
              RETRY: {
                target: "checkRecaptcha",
                actions: assign({ auth: context => (context.attemps = 0) }),
              },
            },
          },
          errorForm: {
            type: "final",
          },
          errorPrepareDynamicKey: {
            type: "final",
          },
          errorPrepareDynamicKeyForm: {
            type: "final",
          },
          errorScore: {
            on: {
              RETRY: [
                { cond: conditions.isRecaptchaV2, target: "validateRecaptcha", actions: actions.setUsername },
                { target: "continueFlow" },
              ],
            },
            type: "final",
          },
          unknowErrror: {
            type: "final",
          },
          failedAttempts: {
            type: "final",
          },
          accesBlocked: {
            on: {
              RETRY: {
                target: "checkRecaptcha",
                actions: assign({ auth: context => (context.attemps = 0) }),
              },
            },
          }
        },
      },
      successSSO: {
        after: {
          2000: {
            target: "redirect",
          },
        },
      },
      redirect: {
        type: "final",
        entry: ["redirectToTarget"],
      },
      errorForm: {
        type: "final",
      },
    },
  },
  {
    actions: {
      redirectUrlAction: assign((context, event) => {
        const { sso_id, sso_expiration, url } = event.data;
        const step = referrerMatchAppAuth ? "FORM_PASSWORD_03" : "FORM_PASSWORD_02"
        customerFlow(context, "0003", step)
        if (context.startUrl.includes("v2")) {
          behavioralAnalysis(context, url)
        }
        if (sso_id !== null) {
          setCookie(sso_id, sso_expiration);
        }
        const urlRedirect = url + getTrace(context) + getState(context)
        return {
          redirectUrl: urlRedirect,
        };
      }),

      redirectSSO: assign((context, event) => {
        const { url, sso_expiration } = event.data.data.data[1].attributes;
        customerFlow(context, "0003", "FORM_PASSWORD_02")
        setCookie(context.sso_id, sso_expiration);
        return {
          redirectUrl: url,
        };
      }),

      redirectToTarget: context => window.location.replace(context.redirectUrl),

      getCodeAuthorization: assign((context, event) => {
        const dataPrepareDynamicKey = prepareDynamicKeyFunction(context, event);
        return {
          dataPrepareDynamicKey: dataPrepareDynamicKey,
        };
      }),

      redirectDynamicKeySf: assign(context => {
        customerFlow(context, "0003", "FORM_DYNAMIC_KEY_03");
        const state = context.urlCode.includes("state") ?  "" : getState(context)
        const urlRedirect = context.urlCode + getTrace(context) + state
        return {
          redirectUrl: urlRedirect,
        };
      }),
    },
  }
);

export default fuaMachine;