import React, { useRef, useMemo } from "react";
import { useMachine } from "@xstate/react";
import { passwordFieldMachine } from "../StateMachines/passwordFieldMachine";
import styled from "styled-components";
import { FOUR, ONE, EIGHT } from "../Constant/Constants";

const InputGroup = styled.div`
  display: flex;
  justify-content: center;
`;

const PasswordInput = styled.input`
  border-radius: 0px !important;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 20px;
  padding: 10px;
  outline: none;
  border: none;
  border-bottom: 1px solid #808285;
  margin-left: 5px;
  margin-right: 5px;
  pointer-events: ${props => props.pointer};

  &:focus{
    border-bottom: 1px solid #ffc20e;
  }

  ${props => {
    if (props.error === undefined) {
      return `border-bottom: 2px solid #808285;`;
    }
  }}
`;

function OtpInput({ setvalues, error, setError, setActiveButton,isRecaptchaActive}) {
  const field1Ref = useRef();
  const field2Ref = useRef();
  const field3Ref = useRef();
  const field4Ref = useRef();
  const fieldMap = useMemo(
    () => ({
      field1Ref,
      field2Ref,
      field3Ref,
      field4Ref,
    }),
    []
  );

  const [machine, send] = useMachine(passwordFieldMachine, {
    actions: {
      moveField: ctx => {
        const { activeFieldName } = ctx;
        const fieldName = `${activeFieldName}Ref`;
        const selectedField = fieldMap[fieldName];
        selectedField.current.focus();
        const userAgent = navigator.userAgent;
        if (userAgent.toLowerCase().includes("android")) {
          selectedField.current.setSelectionRange(ONE, ONE);
        }
      },
      setError: ctx => {
        const { countValue, currentValue } = ctx;
        if (countValue < FOUR) {
          setError("Ingrese los 4 dígitos de la clave");
          setActiveButton(false);
        } else {
          setError(undefined);
          setActiveButton(true);
          setvalues(currentValue);
        }
      },
    },
  });
  const { context } = machine;

  const handleChange = event => {
    const {
      target: { name, value },
    } = event;
    const isEmpty = value.length === 0;
    const eventType = isEmpty ? "REMOVE_VALUE" : "ADD_VALUE";
    if (eventType === "ADD_VALUE") {
      if (!/\d+/.test(value)) {
        return;
      }
    }
    send(eventType, {
      fieldName: name,
      value,
      mask: "*",
    });
  };

  const handleBack = event => {
    const {
      target: { name },
      keyCode,
    } = event;
    const { previousField, value } = context[name];
    if (keyCode === EIGHT && value === "") {
      const fieldName = `${previousField}Ref`;
      const selectedField = fieldMap[fieldName];
      selectedField.current.focus();
      const userAgent = navigator.userAgent;
      if (userAgent.toLowerCase().includes("android")) {
        selectedField.current.setSelectionRange(ONE, ONE);
      }
    }
  };

  const handleFocus = event => {
    const {
      target: { name },
    } = event;
    const fieldName = `${name}Ref`;
    const selectedField = fieldMap[fieldName];
    selectedField.current.setSelectionRange(ONE, ONE);
  };

  const handleClick = () => {
    send("LAST_FIELD");
  };

  const onSelectHandle = () => {
    const userAgent = navigator.userAgent;
    if (userAgent.toLowerCase().includes("android")) {
      send("LAST_FIELD");
      return true;
    } 
    return false;
  };

  const handleChangeCallback = React.useCallback(event => handleChange(event))
  const handleBackCallback = React.useCallback(event => handleBack(event))
  const handleFocusCallback = React.useCallback(event => handleFocus(event))
  const handleClickCallback = React.useCallback(() => handleClick())
  const onSelectHandleCallback = React.useCallback(() => onSelectHandle())
  
  return (
    <InputGroup isRecaptchaActive={isRecaptchaActive}>
      <PasswordInput
        ref={field1Ref}
        data-testid="1"
        type="text"
        inputMode="numeric"
        name="field1"
        error={error}
        maxLength={1}
        value={context.field1.mask}
        onChange={handleChangeCallback}
        onKeyDown={handleBackCallback}
        onFocus={handleFocusCallback}
        onClick={handleClickCallback}
        onSelect={onSelectHandleCallback}
      />
      <PasswordInput
        ref={field2Ref}
        data-testid="2"
        type="text"
        name="field2"
        inputMode="numeric"
        error={error}
        maxLength={1}
        value={context.field2.mask}
        onChange={handleChangeCallback}
        onKeyDown={handleBackCallback}
        onFocus={handleFocusCallback}
        onClick={handleClickCallback}
        onSelect={onSelectHandleCallback}
      />
      <PasswordInput
        ref={field3Ref}
        data-testid="3"
        type="text"
        inputMode="numeric"
        name="field3"
        error={error}
        maxLength={1}
        value={context.field3.mask}
        onChange={handleChangeCallback}
        onKeyDown={handleBackCallback}
        onFocus={handleFocusCallback}
        onClick={handleClickCallback}
        onSelect={onSelectHandleCallback}
      />
      <PasswordInput
        ref={field4Ref}
        data-testid="4"
        type="text"
        inputMode="numeric"
        name="field4"
        error={error}
        maxLength={1}
        value={context.field4.mask}
        onChange={handleChangeCallback}
        onKeyDown={handleBackCallback}
        onFocus={handleFocusCallback}
        onClick={handleClickCallback}
        onSelect={onSelectHandleCallback}
      />
    </InputGroup>
  );
}
export default OtpInput;
