import ButtonTemplate from "../../ButtonTemplate/ButtonTemplate";
import {
    ButtonEnableStyle,
    ButtonDisableStyle,
    ButtonBackStyle
} from "../../ButtonTemplate/ButtonTemplateStyle";
import { ButtonGroup } from "../../../Styles/PasswordFormStyle/ButtonGroup";
import { useHistory, useLocation } from "react-router";
import { referrerMatchAppAuth } from "../../../Constant/Constants"
import React from "react";

export function ButtonGroupAppAtHand({ isValid, isMatch, machine, setShowPopUpGoOut, setIsButtonBack }) {
    const history = useHistory();
    const location = useLocation();

    const showPopUp = React.useCallback(() => {
        setIsButtonBack(true)
        location.pathname = location.pathname.includes("v2") ? location.pathname.replace("appHand/v2", "appAuth/v2") : location.pathname.replace("appHand", "appAuth")
        referrerMatchAppAuth ? history.push(`${location.pathname}${location.search}`) : setShowPopUpGoOut(true)
    });
    return (
        <div>
            <ButtonGroup>
                <ButtonTemplate
                    customStyle={ButtonBackStyle}
                    type="button"
                    title={"Volver"}
                    fuctionButton={showPopUp}
                />
                <ButtonTemplate
                    customStyle={
                        isValid && !isMatch.some(machine.matches) ? ButtonEnableStyle : ButtonDisableStyle
                    }
                    type="submit"
                    title={"Continuar"}
                />
            </ButtonGroup>
        </div>
    );
}
