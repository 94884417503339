import config from "../config";

export const TABLETDEVICEREGEX = new RegExp( "/tablet|Tablet|ipad|iris|kindle|playbook|silk|Silk/i");
export const MOBILE = "MOBILE";
export const PC = "PC";
export const TABLET = "TABLET";
export const ACTIVE_RECAPTCHA = false;

// TAG MANAGER
export const USER = "usuario";
export const ENTEROKAY = "Ingresar";
export const CONTINUE = "Continuar";
export const PASSWORD = "Contraseña";

export const BOTON_BANCOLOMBIA = "BDT";
export const BOTON_BANCOLOMBIA_BTN = "BTN";
export const IFOOD = "IFD";
export const TU360 = "MKA";
export const VECINDARIO = "VEC";
export const HABI = "HAB";
export const elColombiano = "COL";
export const EFA001 = "?code=EFA001";
export const EFA002 = "?code=EFA002";
export const EFA003 = "?code=EFA003";
export const EFA004 = "?code=EFA004";
export const EFA005 = "?code=EFA005";
export const EFA006 = "?code=EFA006";
export const EFA007 = "?code=EFA007";
export const EFA008 = "?code=EFA008";
export const EFA010 = "?code=EFA010"; // pantalla inhabilitado v2
export const EFA011 = "?code=EFA011"; // Tu clave ha expirado (alfanumerica)
export const EFA012 = "?code=EFA012"; // Superaste el número de intentos (alfanumerica)
export const EFA013 = "?code=EFA013"; // Inscripción reciente en CD (token second factor)
export const EFA014 = "?code=EFA014"; // superado el tiempo de inactividad permitido (token second factor)
export const EFA015 = "?code=EFA015"; // superado el número de intentos (token second factor)
export const EFA016 = "?code=EFA016";//  clave bloqueada (token second factor)
export const EFA017 = "?code=EFA017";//  cliente no enrolado alertas y notificaciones (token second factor)
export const EFA018 = "?code=EFA018";//  Segundo factor no registrado

export const THOUSAND = 1000;
export const TEN = 10;
export const EIGHTEEN = 18;

export const ONE = 1;
export const TOW = 2;
export const THREE = 3;
export const FOUR = 4;
export const FIVE = 5;
export const SIX = 6;
export const SIXTEEN = 16;
export const EIGHT = 8
export const TWENTY = 20
export const ELEVEN = 11
export const FIVEHUNDRED = 500
export const THIRTEEN = 13

export const TIMEOUT = 0;

export const USER_AGENT = 255;

export const TIME_OUT_REDIRETION = 2000;
export const MINUS_ONE = -1;
export const CREDENTIALS_CODES_ERROS = ["0001", "0002", "0026", "0027", "0052", "0003", "ER500-01", "ER400-01"];
export const UNAVAILABILITY_CODES_ERROS = [
  "0064",
  "0065",
  "0066",
  "0099",
  "0008",
  "0009",
  "0120",
  "0123",
  "0568",
  "0025",
  "9999",
  "0998",
  "0382",
  "9994",
  "9996",
  "9995",
  "9998",
  "ER404-01",
  "ER404-02",
  "ER404-03",
  "ER404-04",
  "ER404-10",
  "ER404-11",
  "ER500-02",
  "ER401-01",
  "1050"
];
export const COOKIE_NAME = "sso_id";
export const redirectionUrlHomeBank = "https://www.bancolombia.com/";
export const redirectionUrlErrorTu360 = "https://tu360compras.grupobancolombia.com/";
export const contentType = "Content-Type : application/json";
export const scoreRecaptchaCodeErrror = "9997"
export const consumers = [BOTON_BANCOLOMBIA, BOTON_BANCOLOMBIA_BTN, IFOOD, VECINDARIO, HABI, elColombiano, "BTP"]
export const unknowErrror = "9989"
export const options = [
  { value: 'CC', label: 'Cédula de ciudadanía' },
  { value: 'TI', label: 'Tarjeta de identidad' },
  { value: 'PPT', label: 'Permiso de permanencia temporal' },
];
export const userFormPath = "/login/oauth/authorize/"; //us + pin
export const dynamicKeyPath = "/login/oauth/dynamicKey/"; //cd signature deprecate
export const appHandFormPath = "/login/oauth/appHand/"; //alm
export const authDynamicKeyPath = "/login/oauth/user/dynamickey/"; //us + cd --- screen user
export const dynamicKeyScreenPath = "/login/oauth/dynamickeyform/"; //us + cd --- screen dynamic key
export const userFormAlphanumericPath = "/login/oauth/user/alphanumerickey/";
export const alphanumerickeyFormPath = "/alphanumerickey/"
export const passwordFormPath = config.path.PASSWORDFORMPATH
export const tokenSecondFactorFormPath = "/tokenSecondFactorForm/"

//V2
export const userFormPathV2 = "/login/oauth/authorize/v2/"; //us + pin v2 
export const appHandFormPathV2 = "/login/oauth/appHand/v2/"; //alm v2
export const dynamicKeySignaturePathV2 = "/login/oauth/dynamickey/signature/v2/"; // cd signature v2
export const choiceAppHandV2 = "/login/oauth/appAuth/v2"
export const unavailabilityV2 = "/unavailability/v2"
export const passwordFormPathv2 = config.path.PASSWORDFORMPATHV2

export const informationScreen =  {
  width: window.screen.width,
  height: window.screen.height,
  avail_height: window.screen.availHeight,
  avail_width: window.screen.availWidth,
  color_depth: window.screen.colorDepth,
  pixel_depth: window.screen.pixelDepth,
};

export const informationNavigator = {
  user_agent: window.navigator.userAgent.slice(0, USER_AGENT),
  vendor: window.navigator.vendor,
  plugins: window.navigator.plugins,
  plugins_length: window.navigator.plugins.length,
  platform: window.navigator.platform,
  cookie_enabled: window.navigator.cookieEnabled,
  app_name: window.navigator.appName,
  do_not_track: window.navigator.doNotTrack,
  language: window.navigator.language,
  language_length: window.navigator.languages.length,
  hardware_concurrency: window.navigator.hardwareConcurrency,
};

export const windowPathname = window.location.pathname.endsWith("/") ? window.location.pathname : `${window.location.pathname}/`
export const accesBlocked = "ER500-03"
export const timeOutCustomerFlow =  config.customerFlow.TIMEOUTCUSTOMERFLOW;
export const failedAttempts = "9987"
export const cookieNameCsid = "csid"
export const referrerMatchAppAuth = window.document.referrer.match("appAuth")
export const numberCharactersAlphanumericKey = 64
export const expiresTimeCokkie = 240000