import React, { useEffect, useState } from "react";
import MainContent from "../MainContent";
import PrincipalContainer from "../PrincipalContainer"
import Button from "../../Styles/ChoiceAppPayStyle/Button";
import Footer from "../Footer";
import { consumers, EFA002, timeOutCustomerFlow, expiresTimeCokkie } from "../../Constant/Constants";
import getUrlParam, { getTrace, addValueCookie, getDevice } from '../../Utilities/httpUtils'
import TitleHeader from "../../Styles/ChoiceAppPayStyle/TitleHeader";
import { ButtonGroup } from "../../Styles/ChoiceAppPayStyle/ButtonGroup"
import { FaChevronRight } from "../../Styles/ChoiceAppPayStyle/FaChevronRight";
import { Text } from "../../Styles/ChoiceAppPayStyle/Text";
import { useHistory } from "react-router-dom";
import { useMachineContext } from "../../Context/contextMachine";
import { customerFlow } from "../../Services/CustomerFlow";
import { AlertGoOut } from "../AlertGoOut/AlertGoOut"
import faChevronRight from "../../Images/faChevronRight.svg";
import HeaderTemplate from "../HeaderTemplate/HeaderTemplate"
import logoHeader from "../../Images/headerIconBancolombia.svg";
import config from "../../config";
import { contextSwitchHandlerBiocatch, back } from "../../Utilities/GeneralFunctions"
import { loadFormChoiceTypeAtuthDatalayer, clickAppPersonFormDatalayer, clickAppAlmFormDatalayer, pushDatalayerClientIdFormChoice } from "../../HandlerComponents/HandlerDatalayers/HandlerDatalayers"
import { GroupDescriptionText } from "../../Styles/ChoiceAppPayStyle/GroupDescriptionButton/GroupDescriptionText"
import { Subtitle } from "../../Styles/ChoiceAppPayStyle/GroupDescriptionButton/Subtitle"
import { GroupDescription } from "../../Styles/ChoiceAppPayStyle/GroupDescriptionButton/GroupDescription"
import { TextWhichOption } from "../../Styles/ChoiceAppPayStyle/GroupWhichOption/TextWhichOption"
import { FaChevronRightMini } from "../../Styles/ChoiceAppPayStyle/FaChevronRightMini";
import FaChevronRightMiniIcon from "../../Images/faChevronRightMiniIcon.svg";
import { GroupWhichOptionDescription } from "../../Styles/ChoiceAppPayStyle/GroupWhichOption/GroupWhichOptionDescription"
import useModal from "../../MyHooks/useModal";
import Modal from "./Modal";
import { GridContainer } from "../../Styles/ChoiceAppPayStyle/GridContent"
import { useInterval } from 'react-use';

export function ChoiceAppPay() {
  const history = useHistory();
  const [machine] = useMachineContext();
  const [showPopUpGoOut, setShowPopUpGoOut] = useState(false);
  const isV2 = window.document.URL.includes("v2")
  const SVNENABLEDCLIENTS = config.urlAlphanumericKeyAndToken.SVNENABLEDCLIENTS
  const [showFlowSvn, setShowFlowSvn] = useState(false);
  const [typeDevice, setTypeDevice] = useState("PC")
  const { isShowing, toggle } = useModal();

  useEffect(() => {
    contextSwitchHandlerBiocatch("AUTHENTICATION_TYPE_FORM")
    pushDatalayerClientIdFormChoice()
    loadFormChoiceTypeAtuthDatalayer()
  }, []);

  useEffect(async () => {
    setTypeDevice(await getDevice())
  }, []);

  useEffect(() => {
    setShowFlowSvn(SVNENABLEDCLIENTS.includes(getUrlParam("client_id")))
  }, []);

  useEffect(() => {
    customerFlow(machine.context, "0001", "FORM_CHOICE_TYPE_AUTH_01")
  }, []);

  const goToApp = React.useCallback(async flow => {
    const url = window.document.URL
    const listUrl = url.split("appAuth")
    const redirectToAuthorize = `${listUrl[0]}authorize${listUrl[1]}`
    const redirectToAppAtHand = `${listUrl[0]}appHand${listUrl[1]}`
    const redirectToSvn = `${listUrl[0]}user/alphanumerickey${listUrl[1]}`
    history.push(url)

    switch (flow) {
      case "app":
        clickAppPersonFormDatalayer()
        goToFlow(redirectToAuthorize)
        break;
      case "alm":
        clickAppAlmFormDatalayer()
        goToFlow(redirectToAppAtHand)
        break;
      case "svp":
        const redirectToSvnIncludeV2 = redirectToSvn.includes("v2");
        addValueCookie("redirectToSvnIncludeV2", expiresTimeCokkie, redirectToSvnIncludeV2);
        goToFlow(redirectToSvn.replace("v2/", "").replace("v2", ""));
        break;
      default:
        goToFlow(redirectToAuthorize)
    }
  });

  const goToFlow = async url => {
    window.location.replace(url)
  }

  useInterval(() => {
    if (consumers.includes(machine.context.idClient)) {
      window.location.replace(machine.context.redirectUri + EFA002 + getTrace(machine.context));
    }
  }, timeOutCustomerFlow);

  const showPopUp = React.useCallback(() => setShowPopUpGoOut(true))

  return (
    <PrincipalContainer>
      <HeaderTemplate logoHeader={logoHeader} isV2={isV2} backFunction={back} exitFunction={showPopUp} marginBottom='45px' marginBottomMobile='45px' />
      {showPopUpGoOut && <AlertGoOut machine={machine} setShowPopUpGoOut={setShowPopUpGoOut} isButtonBack={false} isV2={isV2} />}
      <MainContent>
        <GridContainer>
          <TitleHeader title="Iniciar sesión" subTitle="Elige cómo quieres continuar:" />
          <ButtonGroup className="row justify-content-md-center">
            <div className="col-sm col-md-auto" style={{ marginBottom: "10px" }}>
              <Button onClick={() => goToApp("app")}>
                <GroupDescription>
                  <GroupDescriptionText><Text>Bancolombia Personas</Text><Subtitle>Si manejas cuentas o productos personales.</Subtitle></GroupDescriptionText>
                  <FaChevronRight image={faChevronRight} backgroundColor={"rgba(253, 218, 36, 1)"} />
                </GroupDescription>
              </Button>
            </div>
            <div className="col-sm col-md-auto" style={{ marginBottom: "10px" }}>
              <Button onClick={() => goToApp("alm")}>
                <GroupDescription>
                  <GroupDescriptionText><Text>Bancolombia A la mano</Text><Subtitle>Si usas esta aplicación.</Subtitle></GroupDescriptionText>
                  <FaChevronRight image={faChevronRight} backgroundColor={"rgba(255, 127, 65, 1)"} />
                </GroupDescription>
              </Button>
            </div>
            <div className="col-sm col-md-auto" hidden={!showFlowSvn} style={{ marginBottom: "10px" }}>
              <Button onClick={() => goToApp("svp")}>
                <GroupDescription>
                  <GroupDescriptionText><Text>Bancolombia Negocios</Text><Subtitle>Si manejas cuentas o productos de negocios.</Subtitle></GroupDescriptionText>
                  <FaChevronRight image={faChevronRight} backgroundColor={"rgba(0, 195, 137, 1)"} />
                </GroupDescription>
              </Button>
            </div>
          </ButtonGroup>

          <GroupWhichOptionDescription onClick={toggle}>
            <TextWhichOption>¿Qué opción debo elegir?</TextWhichOption><FaChevronRightMini hidden={typeDevice === "MOBILE"} image={FaChevronRightMiniIcon}></FaChevronRightMini>
          </GroupWhichOptionDescription>
        </GridContainer>
        <Modal isShowing={isShowing} hide={toggle} showOptionSvn={showFlowSvn} > </Modal>
        {machine.matches("errorForm") &&
          (() => {
            history.push("/unavailability");
          })()}
      </MainContent>
      <Footer top="0" topMovil="560px" />
    </PrincipalContainer>
  );
}
