import Modal from "../../Components/Modal";
import UserBlock from "../../Components/UserBlock";
import ModalError from "../../Components/ModalError";
import ErrorDynamicKey from "../../Components/ErrorDynamicKey";
import errorImageScoreRecaptcha from "../../Images/errorImageScoreRecaptcha.svg";
import ModalErrorTemplate from "../../Components/ModalErrorTemplate/ModalErrorTemplate";
import { redirctFailedAttempts } from "../../Utilities/GeneralFunctions"
import Loader from "../../Components/Loader";
import RedirectionMessage from "../../Components/RedirectionMessage";
import alertIcon from "../../Images/alertIcon.svg";
import { EFA003 } from "../../Constant/Constants"
import { getTrace, getState } from '../../Utilities/httpUtils'
import { errorModalBlockedAccess } from "../HandlerDatalayers/HandlerDatalayers"
import { flowNameUser } from "../../Constant/ConstantsDataLayer"
import React, { useEffect } from "react";

export function HandlerPasswordForm({ machine, isMatch, history, isMatchRedirectMessage }) {
    const executeRedirctFailedAttempts = () => redirctFailedAttempts(machine)

    const back = () => {
        window.location.replace(machine.context.redirectUri + EFA003 + getTrace(machine.context) + getState(machine.context))
    };

    useEffect(() => {
        if (machine.matches("sendCredentials.accesBlocked")) {
            errorModalBlockedAccess(flowNameUser)
        }
    }, [machine]);

    return (
        <div>
            {machine.matches("sendCredentials.prepareDynamicKey") && <Loader />}
            {isMatch.some(machine.matches) && (() => { history.push("/unavailability"); })()}
            {machine.matches("sendCredentials.userBlock") && (
                <Modal
                    isShowing
                    heightM="250px"
                    topM="50%"
                    top="20%"
                    buttonClose={false}
                    widthM="340px"
                >
                    <UserBlock flowName={"autenticacion usuario"} />
                </Modal>
            )}
            {machine.matches("sendCredentials.accesBlocked") && (
                <ModalErrorTemplate
                    isShowCloseButton={false}
                    iconImageSvg={alertIcon}
                    title={"Acceso bloqueado"}
                    description={"Puedes dirigirte a la sucursal más cercana o comunicarte con sucursal telefónica"}
                    height={"330px"}
                    heightM={"330px"}
                    buttonText={"Entendido"}
                    functionButtonDescription={back}
                    hiddenAditionalDescription={true}
                />
            )}
            {machine.matches("sendCredentials.errorScore") && (
                <ModalError
                    isShowing
                    topM="50%"
                    top="20%"
                    buttonClose={false}
                    startUrl={machine.context.startUrl}
                    title="No pudimos validar que eres tú"
                    message="Intentémoslo nuevamente."
                    titleBotton="Regresar al sitio"
                />
            )}

            {machine.matches("sendCredentials.errorPrepareDynamicKeyForm") && (
                <Modal
                    isShowing
                    height="720px"
                    topM="0%"
                    top="0%"
                    buttonClose={false}
                    width="430px"
                    widthM="340px"
                >
                    <ErrorDynamicKey
                        codeError={machine.context.errorCodeDynamicKey}
                    />
                </Modal>
            )}

            {machine.matches("sendCredentials.failedAttempts") && (
                <ModalErrorTemplate
                    isShowCloseButton={true}
                    iconImageSvg={errorImageScoreRecaptcha}
                    title={"Autenticación fallida"}
                    description={"Tuvimos problemas para realizar tu proceso de autenticación, por seguridad podrás continuar dentro de:"}
                    additionalDescription={machine.context.timeFailedAttempts}
                    height={"363px"}
                    heightM={"370px"}
                    buttonText={"Entendido"}
                    fuctionButtonClose={executeRedirctFailedAttempts}
                    functionButtonDescription={executeRedirctFailedAttempts}
                    hiddenAditionalDescription={false}
                />
            )}

            {machine.matches("sendCredentials.validateDynamicKeySecondFactor") && history.push("/dynamicKeyForm")}
            {isMatchRedirectMessage.some(machine.matches) && (<RedirectionMessage />)}
        </div>
    )
}