import { listUrlRedirect, listClientId } from "../Constant/ConstantsUrlRedirect";
import { getTrace, getState } from "../Utilities/httpUtils";
import { EFA006, redirectionUrlHomeBank, BOTON_BANCOLOMBIA_BTN, BOTON_BANCOLOMBIA, referrerMatchAppAuth, cookieNameCsid, expiresTimeCokkie } from "../Constant/Constants";
import config from "../config";
import { getValueCookie, generateUuid, addValueCookie } from "./httpUtils"

export const redirctFailedAttempts = machine => {
    const clientId = machine.context.idClient;
    const btn = [BOTON_BANCOLOMBIA_BTN, BOTON_BANCOLOMBIA]
    const domain = config.domain.ENVIRONMENT_DOMAIN

    if (btn.includes(clientId)) {
        replaceUrl(machine.context.redirectUri + EFA006 + getTrace(machine.context) + getState(machine.context));
    } else if (listClientId.includes(clientId)) {
        let url = redirectionUrlHomeBank
        listUrlRedirect.forEach(key => {
            if (key.clientId === clientId) {
                switch (domain) {
                    case "dev":
                        url = key.urlRedirectDev
                        break;
                    case "qa":
                        url = key.urlRedirectQa
                        break;
                    case "pdn":
                        url = key.urlRedirectPdn
                        break;
                    default:
                        break;
                }
            }
        });
        replaceUrl(url);
    } else {
        replaceUrl(redirectionUrlHomeBank);
    }

}

export const replaceUrl = url => {
    window.location.replace(url);
}

export const back = () => window.history.back();

export const cancelTransaction = (machine, efa) => window.location.replace(machine.context.redirectUri + efa + getTrace(machine.context) + getState(machine.context));

export const cancelTransactionHome = () => window.location.replace(redirectionUrlHomeBank);

export const setCustomerSessionId = async () => {
    try {
        let csid = ""
        if (referrerMatchAppAuth != null && referrerMatchAppAuth.length > 0) {
            csid = await getValueCookie(cookieNameCsid)
        } else {
            const uuid = generateUuid()
            await addValueCookie(cookieNameCsid, expiresTimeCokkie, uuid)
            csid = uuid
        }
        window.cdApi.setCustomerSessionId(csid)
        return "success"
    } catch (error) {
        return error;
    }
}

export const contextSwitchHandlerBiocatch = name => {
    try {
        window.cdApi.changeContext(name)
    } catch (e) {
        return e;
    }
}

export const validateRedirect = async () => {
    let redirectUrl = "";
    try {
        const timing = performance.getEntriesByType(null)[0];
        if (timing.type === "navigate") {
            localStorage.setItem("startUrl", document.URL)
        } else {
            const url = localStorage.getItem("startUrl");
            if (url && window.location.href !== url && timing.type === "reload") {
                redirectUrl = url;
            }
        }
        return {
            redirectUrl: redirectUrl,
        };
    } catch (e) {
        return e
    }
};