import AlertMessage from "../../Components/AlertMessage";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    EFA002,
    timeOutCustomerFlow,
    dynamicKeyScreenPath
} from "../../Constant/Constants";
import { getTrace, getState } from "../../Utilities/httpUtils";
import { contextSwitchHandlerBiocatch } from "../../Utilities/GeneralFunctions"
import { customerFlow } from "../../Services/CustomerFlow";
import { flowNameDynamicKeyFirstFactor } from "../../Constant/ConstantsDataLayer"
import {loadFormUserDynamicKey, clickContinueFormUserDynamicKey, pushDatalayerClientIdUserDynamicKey} from "../../HandlerComponents/HandlerDatalayers/HandlerDatalayers"
import { useInterval } from 'react-use';

export function HandlerUserDynamicKeyForm({ machine, send }) {
    const isMatchCredentialsError = ["sendCredentials.error", "validateUserDynamicKey.errorIncorrectData", "validateAlphanumericKeyAndTokenSf.errorIncorrectData"]
    const isMatchForm = ["sendCredentials.continueFlow", "validateUserDynamicKey.continueFlow", "validateAlphanumericKeyAndTokenSf.continueFlow"]
    const history = useHistory();
    const [isDataIncorrect, setIsDataIncorrect] = useState(false);
    const [showAlert, setShowAlert] = useState(true);

    useEffect(() => {
        pushDatalayerClientIdUserDynamicKey()
        loadFormUserDynamicKey()
    }, []);

    useEffect(() => {
        contextSwitchHandlerBiocatch("USER_FORM_DYNAMIC_KEY")
    }, []);

    useEffect(() => {
        customerFlow(machine.context, "0001", "FORM_USER_01")
    }, []);

    useEffect(() => {
        const errorTag = document.getElementById("errorAlert");
        if (errorTag) {
            errorTag.scrollIntoView(true);
            window.scrollTo(0, 0);
        }
    }, []);

    useInterval(() => {
        window.location.replace(machine.context.redirectUri + EFA002 + getTrace(machine.context) + getState(machine.context));
    }, timeOutCustomerFlow);

    const handleMatchForm = () => {
        history.push(dynamicKeyScreenPath);
        clickContinueFormUserDynamicKey()
    };

    const handleMatchCredentialsError = () => {
        const hasIncorrectData = showAlert && isMatchCredentialsError.some(machine.matches);
        setIsDataIncorrect(hasIncorrectData);
    };

    useEffect(() => {
        if (isMatchForm.some(machine.matches)) {
            handleMatchForm();
        } else {
            handleMatchCredentialsError();
        }
    }, [machine, showAlert]);

    const close = () => {
        setShowAlert(false);
        send("RETRY");
    };

    return (
        <>
            {isDataIncorrect && (
                <AlertMessage close={close} title="Datos incorrectos" message="Los datos ingresados no coinciden, inténtalo nuevamente." flowName={flowNameDynamicKeyFirstFactor} />
            )}
        </>
    )
}