import { SIXTEEN, TOW, ONE, THREE,FOUR, TEN, ELEVEN, FIVEHUNDRED } from "../Constant/Constants";
import { executeDateTimeUtc } from "../Utilities/httpUtils";
import config from "../config"
import { regexOneEncryptPin, regexTwoEncryptPin } from "../Utilities/Regex"

const CHARLENGTH = 40;
const gCrypto = window.crypto || window["msCrypto"];
const gSubtle = gCrypto.subtle;
const result = "";
const onError = "";
let modulus;
const ACTIVETIMESTAMP = config.pin.ACTIVETIMESTAMP

export const setModulus = value => {
  modulus = value;
};

const _streamStringToHex = stream => {
  let hex = "";
  for (let i = 0; i < stream.length; i++) {
    hex += "" + stream.charCodeAt(i).toString(SIXTEEN);
  }
  return hex;
};

const _parseId = id => {
  let cont = 0;
  /*
   * Id stream for handled
   * @type {array}
   * */
  const idStream = [];
  for (let i = 0; i < id.length; i++) {
    idStream.push(id.charAt(cont++));
  }
  for (let i = id.length; i < CHARLENGTH; i++) {
    idStream.push(0);
  }
  return idStream.join("");
};

const _parsepassword = password => {
  const PASSWORD_MAX = 4;
  const CHARFILL = "F";
  const CHARFORMAT = "0";
  let copiapassword = [];
  const passwordStream = [];

  // Ansi 0 Format
  passwordStream.push(CHARFORMAT);
  // Pasword pin length
  passwordStream.push(PASSWORD_MAX);

  // add valid digits and padding
  copiapassword = password.split("");
  for (let i = 0; i < copiapassword.length; i++) {
    passwordStream.push(copiapassword[i]);
  }
  for (let i = passwordStream.length; i < CHARLENGTH; i++) {
    passwordStream.push(CHARFILL);
  }

  return passwordStream.join("");
};

const _streamToHex = stream => stream.toString(SIXTEEN).toUpperCase();

const _hexadecimalToBinary = string => {
  // lookup table for rapid conversion
  /**
   * @const
   * @type {{0: string, ..: string, F: string}}
   */
  const LOOKUPTABLE = {
    0: "0000",
    1: "0001",
    2: "0010",
    3: "0011",
    4: "0100",
    5: "0101",
    6: "0110",
    7: "0111",
    8: "1000",
    9: "1001",
    a: "1010",
    b: "1011",
    c: "1100",
    d: "1101",
    e: "1110",
    f: "1111",
    A: "1010",
    B: "1011",
    C: "1100",
    D: "1101",
    E: "1110",
    F: "1111",
  };
  let bin = "";
  let valid = false;

  for (const value of string) {
    if (LOOKUPTABLE.hasOwnProperty(value)) {
      bin += LOOKUPTABLE[value];
      valid = true;
    } else {
      valid = false;
    }
  }
  return {
    bin: bin,
    valid: valid,
  };
};

const _binToHex = bin => {
  let accum = 0;
  let byte = "";
  let hex = "";
  let i,
    k = 0;
  let valid = false;

  for (i = bin.length - ONE; i >= THREE; i -= FOUR) {
    // Dividir en strings de 4 bites y convertir a hex
    byte = bin.substr(i + ONE - FOUR, FOUR);
    accum = 0;
    for (k = 0; k < FOUR; k += ONE) {
      if (byte[k] !== "0" && byte[k] !== "1") {
        // Caracter invalido
        valid = false;
      } else {
        valid = true;
      }

      accum = accum * TOW + parseInt(byte[k], TEN);
    }
    if (accum >= TEN) {
      // 'A' a la 'F'
      hex = String.fromCharCode(accum - TEN + "A".charCodeAt(0)) + hex;
    } else {
      // '0' al '9'
      hex = String(accum) + hex;
    }
  }

  if (i >= 0) {
    accum = 0;
    for (k = 0; k <= i; k += 1) {
      if (bin[k] !== "0" && bin[k] !== "1") {
        valid = false;
      } else {
        valid = true;
      }
      accum = accum * TOW + parseInt(bin[k], 10);
    }

    hex = String(accum) + hex;
  }
  return {
    valido: valid,
    res: hex,
  };
};

const _xorStreams = (streamOne, streamTwo) => {
  const pinBLockStream = [];

  if (streamOne.length !== streamTwo.length) {
    return false;
  } else {
    streamOne = _hexadecimalToBinary(streamOne);
    streamTwo = _hexadecimalToBinary(streamTwo);
    for (let i = 0; i < streamOne.bin.length; i++) {
      pinBLockStream.push(streamOne.bin[i] ^ streamTwo.bin[i]);
    }
  }
  const pinBLock = _binToHex(pinBLockStream.join(""));
  return streamOne.valid && streamTwo.valid ? pinBLock.res : false;
};

const b64tob64u = a => {
  a = a.replace(/\=/g, "");
  a = a.replace(/\+/g, "-");
  a = a.replace(/\//g, "_");
  return a;
};

const _hexToBase64 = str => {
  return btoa(
    String.fromCharCode.apply(
      null,
      str
        .replace(regexOneEncryptPin, "")
        .replace(regexTwoEncryptPin, "0x$1 ")
        .trim()
        .split(" ")
    )
  );
};

const _arrayBufferToBase64 = buffer => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

const base64toHEX = base64 => {
  const raw = atob(base64);
  let HEX = "";
  for (let i = 0; i < raw.length; i++) {
    const _hex = raw.charCodeAt(i).toString(SIXTEEN);
    HEX += _hex.length === TOW ? _hex : "0" + _hex;
  }
  return HEX.toUpperCase();
};

const stringToArrayBuffer = str => {
  // : returnType Uint8Array | BufferSource
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  
  for (let i = 0; i < str.length; i++) {
    const str2 = str.charCodeAt(i);
    bufView[i] = str2;
  }
  return buf; // or bufView
};

const encryptDataWithPublicKey = (data, key) => {
  const arrayBuffer = stringToArrayBuffer(data);
  return window.crypto.subtle.encrypt(
    {
      name: "RSA-OAEP",
    },
    key, //from generateKey or importKey above
    arrayBuffer //ArrayBuffer of data you want to encrypt
  );
};

const _streamToRSA = async pinStream => {
  const publicKey = await gSubtle
    .importKey(
      "jwk",
      {
        kty: "RSA",
        e: b64tob64u("AQAB"), // EN HEX => 1 00 01
        //e: this.b64tob64u(this._hexToBase64(BcOaep.exponent.trim())),
        n: b64tob64u(_hexToBase64(modulus.trim())),
        alg: "RSA-OAEP-256",
        ext: true,
      },
      {
        name: "RSA-OAEP",
        hash: { name: "SHA-256" },
      },
      false,
      ["encrypt"]
    )
    .then(key => key);

  return encryptDataWithPublicKey(
    pinStream,
    publicKey
  ).then(encryptResult => {
    const base64String = _arrayBufferToBase64(encryptResult);
    return base64toHEX(base64String);
  });
};

/*
 * Se parsea el documento para que tome los ultimos 12 digitos y el sobrante lo rellena con 0 a la izquiera
 * @id id user
 * */
const _completeId = id => {
  const last12 = id.substr(0, ELEVEN);
  const zeroQuatity = SIXTEEN - last12.length;
  let newId = last12;
  for (let _i = 0; _i < zeroQuatity; _i++) {
    newId = "0" + newId;
  }
  return newId;
};

export const executePinWithTimestamp = pinStream=>{
  let pin = ""
  
  if(ACTIVETIMESTAMP === "true"){
    const dateTime = executeDateTimeUtc()
    pin = `${pinStream}${"#"}${dateTime}`
  }else{
    pin = pinStream
  }
  return pin;
}

export const encryptAuth =  (idUser, password, userType) => {
  if (userType === "usuario") {
    let passwordStream;
    idUser = _streamStringToHex(idUser);
    const idStream = _parseId(idUser);
    passwordStream = _parsepassword(password);
    passwordStream = _streamToHex(passwordStream);
    const pinStream = _xorStreams(idStream, passwordStream);
    const pin = executePinWithTimestamp(pinStream)
    return _streamToRSA(pin);
  } else {
    const idStream = _completeId(idUser);
    const passwordStream = `04${password}FFFFFFFFFF`;
    const pinStream = _xorStreams(idStream, passwordStream);
    const pin = executePinWithTimestamp(pinStream)
    return _streamToRSA(pin);
  }
};

export const getResult = () => result;

export function onClickInput(clickInput) {
  // create invisible dummy input to receive the focus first
  const userAgent = navigator.userAgent;
  const fakeInput = document.createElement("input");
  fakeInput.setAttribute("type", "password");
  fakeInput.setAttribute("inputMode", "numeric");
  fakeInput.style.position = "absolute";
  fakeInput.style.opacity = 0;
  fakeInput.style.height = 0;
  fakeInput.style.fontSize = "16px"; // disable auto zoom
  // you may need to append to another element depending on the browser's auto
  // zoom/scroll behavior
  const formGroup = document.getElementById("formGroup");
  formGroup.prepend(fakeInput);
  // focus so that subsequent async focus will work
  fakeInput.focus();
  // now we can focus on the target input
  if (
    userAgent.toLowerCase().includes("iphone") ||
    userAgent.toLowerCase().includes("ipad")
  ) {
    setTimeout(() => {
      clickInput.focus();
      // cleanup
      fakeInput.remove();
    }, FIVEHUNDRED);
  } else {
    clickInput.focus();
    fakeInput.remove();
  }
}
