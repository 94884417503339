import getUrlParam, { getDevice, getValueCookie, getTrace, getState } from "../Utilities/httpUtils";
import axios from "axios";
import config from "../config";
import { createMachine, assign } from "xstate";
import {
  USER_AGENT,
  contentType,
  UNAVAILABILITY_CODES_ERROS,
  MINUS_ONE,
  scoreRecaptchaCodeErrror,
  cookieNameCsid
} from "../Constant/Constants";
import { codeDynamicKeyIncorrect, codeDynamicKeyMaxAttemps, listCodeErroDynamicKey } from "../Constant/ConstantsDynamicKey";
import { customerFlow } from "../Services/CustomerFlow";
import { behavioralAnalysis } from "../Services/BehavioralAnalysis";
import { validateRedirect } from "../Utilities/GeneralFunctions"

const URLGETIP = config.url.URLGETIP;
const URLAUTHORIZE = config.url.URLAUTHORIZE;
const URLAUTHUSERDYNAMICKEY = config.url.URLAUTHUSERDYNAMICKEY;

const actions = {
  setUsername: assign({ username: (_context, event) => event.username })
};

const authDcFetchValidateParameters = async () => {
  const ip = await fetch(URLGETIP).then(response => response.json());
  return {
    currentIp: ip,
    idClient: getUrlParam("client_id"),
    responseType: getUrlParam("response_type"),
    redirectUri: getUrlParam("redirect_uri"),
    trace: getUrlParam("trace"),
    prompt: getUrlParam("prompt"),
    linkCode: getUrlParam("linkCode"),
    businessPartner: getUrlParam("businessPartner"),
    startUrl: document.URL,
    state: getUrlParam("state"),
  };
};

const authDcGetBodyUrlauthorize = async context => {
  return {
    data: {
      data: [
        {
          type: "validation",
          id: context.idClient,
          attributes: {
            responseType: context.responseType,
            redirectUri: context.redirectUri,
            ipConsumidor: context.currentIp.ip,
            trace: context.trace,
            prompt: context.prompt,
            linkCode: context.linkCode,
            businessPartner: context.businessPartner,
          },
        },
      ],
    },
  };
}

const authorizeAuth = async context => {
  const PARAMS = await authDcGetBodyUrlauthorize(context);
  if (context.idClient !== "" && context.idClient !== null) {
    const headersAuthorize = { contentType };
    return axios
      .post(URLAUTHORIZE, PARAMS.data, { headersAuthorize })
      .then(response => response.data);
  }
  throw new Error("clientId no especificado");
};

const validateAuthDk = async (context, event) => {
  const request = await getDataValidateAuthDk(context, event);
  const headersValidateDynamicKey = { contentType };
  return axios
    .post(URLAUTHUSERDYNAMICKEY, request, { headersValidateDynamicKey })
    .then(response => response.data);
};

const getDataValidateAuthDk = async (context, event) => {
  const { captchaToken, username, values } = event;
  return {
    username: username,
    dynamicKeyCode: values,
    useragent: window.navigator.userAgent.slice(0, USER_AGENT),
    consumerId: context.idClient,
    device: await getDevice(),
    localaddress: context.currentIp.ip,
    captchatoken: captchaToken,
    redirect_uri: context.redirectUri,
    trace: context.trace,
    segment: context.idClient,
    csid: await getValueCookie(cookieNameCsid),
    state: context.state,
  };
}

const initialContext = {
  status: "",
  error: "",
  currentIp: "",
  idClient: "",
  responseType: "",
  redirectUri: "",
  auth: "",
  attemps: 0,
  trace: "",
  errorCode: "",
  prompt: "",
  linkCode: "",
  businessPartner: "",
  startUrl: "",
  recapvisible: true,
  username: "",
  state: ""
};

const authDynamicKeyMachine = createMachine(
  {
    id: "authDynamicKey",
    initial: "validateRedirect",
    context: initialContext,
    states: {
      validateRedirect: {
        invoke: {
          id: "validateRedirectId",
          src: validateRedirect,
          onDone: [
            {
              cond: (_context, event) => event.data.redirectUrl,
              actions: assign({ redirectUrl: (_context, event) => event.data.redirectUrl }),
              target: "redirect",
            },
            {
              target: "authValidateParameters",
            },
          ],
          onError: {
            target: "errorForm",
          },
        },
      },
      authValidateParameters: {
        invoke: {
          id: "authValidateParametersId",
          src: authDcFetchValidateParameters,
          onDone: {
            target: "authValidateUrl",
            actions: assign({
              trace: (_context, event) => event.data.trace,
              prompt: (_context, event) => event.data.prompt,
              linkCode: (_context, event) => event.data.linkCode,
              businessPartner: (_context, event) => event.data.businessPartner,
              startUrl: (_context, event) => event.data.startUrl,
              currentIp: (_context, event) => event.data.currentIp,
              idClient: (_context, event) => event.data.idClient,
              responseType: (_context, event) => event.data.responseType,
              redirectUri: (_context, event) => event.data.redirectUri,
              state: (_context, event) => event.data.state,
            }),
          },
          onError: {
            target: "errorForm",
          },
        },
      },
      authValidateUrl: {
        invoke: {
          id: "AuthValidateUrlId",
          src: authorizeAuth,
          onError: {
            target: "errorForm",
            actions: assign({
              error: (_context, event) => event.data,
            }),
          },
          onDone: [
            {
              target: "errorForm",
              cond: (_context, event) => event.data.errors,
              actions: assign({ errorCode: (_context, event) => event.data.errors[0].code }),
            },
            {
              target: ["validateUserDynamicKey"],
              actions: assign({
                status: (_context, event) => event.data
              }),
            },
          ]
        },
      },
      validateUserDynamicKey: {
        initial: "checkRecaptcha",
        states: {
          checkRecaptcha: {
            on: {
              RETRY: { target: "continueFlow",  actions: actions.setUsername  }
            }
          },
          continueFlow:{
            after: {
              1: {  target: "validate" },
            }
          },
          validate: {
            on: {
              VALIDATEDYNAMICKEY: { target: "validateUserDynamicKey"  },
              RETRY: { target: "continueFlow",  actions: actions.setUsername  }
            },
          },
          validateUserDynamicKey: {
            invoke: {
              id: "validateAuthDk",
              src: validateAuthDk,
              onError: [
                {
                  target: "errorService",
                  actions: assign({ errorCode: (_context, event) => event.data }),
                }
              ],
              onDone: [
                {
                  cond: (_context, event) => event.data.errors && UNAVAILABILITY_CODES_ERROS.indexOf(event.data.errors[0].code) !== MINUS_ONE,
                  target: "errorService",
                  actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                },
                {
                  cond: (_context, event) => event.data.errors && codeDynamicKeyIncorrect.indexOf(event.data.errors[0].code) !== MINUS_ONE,
                  target: "errorIncorrectData",
                  actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                },
                {
                  cond: (_context, event) => event.data.errors && event.data.errors[0].code === codeDynamicKeyMaxAttemps,
                  target: "errorMaxtries",
                  actions: assign({ errorCode: (_context, event) => event.data.errors[0].code })
                },
                {
                  cond: (_context, event) => event.data.errors && listCodeErroDynamicKey.indexOf(event.data.errors[0].code) !== MINUS_ONE,
                  actions: assign({ errorCode: (_context, event) => event.data.errors[0].code }),
                  target: "generalError",
                },
                {
                  cond: (_context, event) => event.data.errors && event.data.errors[0].code === scoreRecaptchaCodeErrror,
                  actions: assign({ errorCode: (_context, event) => event.data.errors[0].code }),
                  target: "errorScore",
                },
                {
                  cond: (_context, event) => event.data.errors,
                  actions: assign({ errorCode: (_context, event) => event.data.errors[0].code }),
                  target: "errorService"
                },
                {
                  target: "success",
                  actions: ["redirectToUrl"],
                }
              ]
            },
          },
          generalError: {
            type: "final",
          },
          errorMaxtries: {
            type: "final",
          },
          errorIncorrectData: {
            on: {
              RETRYAUTH: {
                target: "checkRecaptcha",
              },
              RETRY: {
                target: "checkRecaptcha",
              },
            },
            type: "final",
          },
          errorService: {
            on: {
              RETRYAUTH: {
                target: "checkRecaptcha",
              },
            },
            type: "final",
          },
          errorScore: {
            on: {
              RETRY: { target: "continueFlow", actions: actions.setUsername  }
            },
            type: "final",
          },
          success: {
            after: {
              2000: {
                type: "final",
              },
            },
          }
        }
      },
      redirect: {
        type: "final",
        entry: ["redirectToTarget"],
      },
      success: {
        after: {
          2000: {
            type: "final",
          },
        },
      },
      errorForm: {
        type: "final",
      }
    },
  },
  {
    actions: {
      redirectToUrl: assign((context, event) => {
        const { url } = event.data;
        customerFlow(context, "0003", "FORM_DYNAMIC_KEY_02")
        behavioralAnalysis(context, url)
        const urlRedirectDk = url + getTrace(context)
        window.location.replace(urlRedirectDk)
      }),
      redirectToTarget: context => window.location.replace(context.redirectUrl)
    },
  }
);

export default authDynamicKeyMachine;