import getUrlParam from "../../Utilities/httpUtils";
import {
    flowNameUser,
    flowNameDynamicKeyFirstFactor,
    flowNameAuthOtp,
    flowNameDynamicKey,
    next,
    back,
    validate,
    stepNameUser,
    stepEnterPassword,
    stepNameDynamicKey,
    stepNameChooseAppPay,
    stepNameEnterPersonalData,
    stepNameEnterSecurityCode,
    flowNameLoginScreen,
    elementFlowPersons,
    elementFlowAlm,
    stepNameDynamicKeyFirstFactor
} from "../../Constant/ConstantsDataLayer"

const pushDatalayer = async (event, nombreflujo, nombrePaso, elemento) => {
    try {
        window.dataLayer.push({
            "event": event,
            "nombreflujo": nombreflujo,
            "nombrePaso": nombrePaso,
            "elemento": elemento,
            "clientID": getUrlParam("client_id"),
        })
        return true
    } catch (e) {
        return `error datalayer ${e}`
    }
}

const pushDatalayerScreensErrors = async (event, nombreflujo, error) => {
    try {
        window.dataLayer.push({
            "event": event,
            "nombreflujo": nombreflujo,
            "errror": error
        })
        return true
    } catch (e) {
        return `error datalayer ${e}`
    }
}

const pushDatalayerClientId = async nombreflujo => {
    try {
        window.dataLayer.push({
            "nombreflujo": nombreflujo,
            "clientID": getUrlParam("client_id"),
            "event": "SEND_CLIENT_ID",
          });
        return true
    } catch (e) {
        return `error datalayer ${e}`
    }
}

//Flow user + pin
export const pushDatalayerClientIdUserPin = async () => {
    pushDatalayerClientId(flowNameUser)
}

export const loadFormUserDatalayer = async () => {
    pushDatalayer("ENTER_USER_FUA", flowNameUser, stepNameUser, "")
}

export const clickContinueFormUserDatalayer = async () => {
    pushDatalayer("SEND_USER_FUA", flowNameUser, stepNameUser, next)
}

export const loadFormPasswordDatalayer = async () => {
    pushDatalayer("ENTER_PASSWORD_FUA", flowNameUser, stepEnterPassword, "")
}

export const clickContinueFormPasswordDatalayer = async () => {
    pushDatalayer("SEND_PASSWORD_FUA", flowNameUser, stepEnterPassword, next)
}

export const clickBackFormPasswordDatalayer = async () => {
    pushDatalayer("SEND_PASSWORD_FUA", flowNameUser, stepEnterPassword, back)
}

//Flow user + pin + dynamicKey
export const pushDatalayerClientIdUserPinDynamicKey = async () => {
    pushDatalayerClientId(flowNameDynamicKey)
}

export const loadFormUserDynamicKeyDatalayer = async () => {
    pushDatalayer("ENTER_USER_DYNAMIC_KEY", flowNameDynamicKey, stepNameUser, "")
}

export const clickContinueFormUserDynamicKeyDatalayer = async () => {
    pushDatalayer("SEND_USER_DYNAMIC_KEY", flowNameDynamicKey, stepNameUser, next)
}

export const loadFormPasswordDynamicKeyDatalayer = async () => {
    pushDatalayer("ENTER_PASSWORD_DYNAMIC_KEY", flowNameDynamicKey, stepEnterPassword, "")
}
export const clickContinueFormPasswordDynamicKeyDatalayer = async () => {
    pushDatalayer("SEND_PASSWORD_DYNAMIC_KEY", flowNameDynamicKey, stepEnterPassword, next)
}

export const clickBackFormPasswordDynamicKeyDatalayer = async () => {
    pushDatalayer("SEND_PASSWORD_DYNAMIC_KEY", flowNameDynamicKey, stepEnterPassword, back)
}

export const loadFormDynamicKeyDatalayer = async () => {
    pushDatalayer("ENTER_DYNAMIC_KEY", flowNameDynamicKey, stepNameDynamicKey, "")
}
export const clickValidateFormDynamicKeyDatalayer = async () => {
    pushDatalayer("SEND_DYNAMIC_KEY", flowNameDynamicKey, stepNameDynamicKey, validate)
}

//flow dynamic key first factor
export const pushDatalayerClientIdDynamicKeyFirstFactor = async () => {
    pushDatalayerClientId(flowNameDynamicKeyFirstFactor)
}

export const loadFormUserDynamicKeyFirstFactorDatalayer = async () => {
    pushDatalayer("ENTER_DYNAMIC_KEY_FIRST_FACTOR", flowNameDynamicKeyFirstFactor, stepNameDynamicKeyFirstFactor, "")
}
export const clickValidateFormDynamicKeyFirstFactorDatalayer = async () => {
    pushDatalayer("SEND_DYNAMIC_KEY_FIRST_FACTOR", flowNameDynamicKeyFirstFactor, stepNameDynamicKeyFirstFactor, validate)
}

//flow choice type atuh
export const pushDatalayerClientIdFormChoice = async () => {
    pushDatalayerClientId(flowNameLoginScreen)
}
export const loadFormChoiceTypeAtuthDatalayer = async () => {
    pushDatalayer("CHOOSE_TYPE_AUTHENTICATION", flowNameLoginScreen, stepNameChooseAppPay, "")
}
export const clickAppPersonFormDatalayer = async () => {
    pushDatalayer("SEND_TYPE_AUTHENTICATION", flowNameLoginScreen, stepNameChooseAppPay, elementFlowPersons)
}
export const clickAppAlmFormDatalayer = async () => {
    pushDatalayer("SEND_TYPE_AUTHENTICATION", flowNameLoginScreen, stepNameChooseAppPay, elementFlowAlm)
}

//if choice alm
export const pushDatalayerClientIdPersonalData = async () => {
    pushDatalayerClientId(flowNameAuthOtp)
}
export const loadFormPersonalDataDatalayer = async () => {
    pushDatalayer("ENTER_PERSONAL_DATA", flowNameAuthOtp, stepNameEnterPersonalData, "")
}
export const clickContinueFormPersonalDataDatalayer = async () => {
    pushDatalayer("SEND_PERSONAL_DATA", flowNameAuthOtp, stepNameEnterPersonalData, next)
}

export const loadFormSecurityCodeDatalayer = async () => {
    pushDatalayer("ENTER_SECURITY_CODE", flowNameAuthOtp, stepNameEnterSecurityCode, "")
}

export const clickContinueFormSecurityCodeDatalayer = async () => {
    pushDatalayer("SEND_SECURITY_CODE", flowNameAuthOtp, stepNameEnterSecurityCode, validate)
}

//if choice app personas
export const pushDatalayerClientIdAppPerson = async () => {
    pushDatalayerClientId(flowNameUser)
}
export const loadFormAppPerson = async () => {
    pushDatalayer("ENTER_USER_FUA", flowNameUser, stepNameUser, "")
}

export const clickContinueFormAppPersonDatalayer = async () => {
    pushDatalayer("SEND_USER_FUA", flowNameUser, stepNameUser, next)
}

export const loadFormPasswordAppPersonDatalayer = async () => {
    pushDatalayer("ENTER_PASSWORD_FUA", flowNameUser, stepEnterPassword, "")
}

export const clickContinueFormPasswordAppPersonDatalayer = async () => {
    pushDatalayer("SEND_PASSWORD_FUA", flowNameUser, stepEnterPassword, next)
}

export const clickBackFormPasswordAppPersonDatalayer = async () => {
    pushDatalayer("SEND_PASSWORD_FUA", flowNameUser, stepEnterPassword, back)
}

//error modal messagess
export const errorModalDataIncorrect = async flowName => {
    pushDatalayerScreensErrors("SEND_LOGIN_ERROR", flowName, "El usuario o la contraseña no son correctos. Intenta de nuevo")
}

export const errorModalUnavailability = async flowName => {
    pushDatalayerScreensErrors("SEND_SYSTEM_ERROR", flowName, "El sistema no se encuentra disponible")
}

export const errorModalBlockedAccess = async flowName => {
    pushDatalayerScreensErrors("SEND_ACCESS_ERROR", flowName, "acceso bloqueado")
}

//usuario + clave dinamica
export const pushDatalayerClientIdUserDynamicKey = async () => {
    pushDatalayerClientId(flowNameDynamicKeyFirstFactor)
}

export const loadFormUserDynamicKey = async () => {
    pushDatalayer("ENTER_USER_DYNAMIC_KEY_FIRST_FACTOR", flowNameDynamicKeyFirstFactor, stepNameUser, "")
}

export const clickContinueFormUserDynamicKey = async () => {
    pushDatalayer("SEND_USER_DYNAMIC_KEY_FIRST_FACTOR", flowNameDynamicKeyFirstFactor, stepNameUser, next)
}

export const loadFormPasswordUserDynamicKey = async () => {
    pushDatalayer("ENTER_DYNAMIC_KEY_FIRST_FACTOR", flowNameDynamicKeyFirstFactor, stepNameDynamicKey, "")
}

export const clickContinueFormPasswordUserDynamicKey = async () => {
    pushDatalayer("SEND_DYNAMIC_KEY_FIRST_FACTOR", flowNameDynamicKeyFirstFactor, stepNameDynamicKey, validate)
}