import React, { useState, useEffect } from "react";
import { useMachineContext } from "../Context/contextMachine";
import Container from "./Container";
import ContainerHeader from "./ContainerHeader";
import PrincipalContainer from "./PrincipalContainer";
import Footer from "./Footer";
import MainContent from "./MainContent";
import OtpInputDynamicKey from "./OtpInputDynamicKey";
import AlertMessageDynamicKey from "./AlertMessageDynamicKey"
import { timeExpirationDynamicKey } from "../Constant/ConstantsDynamicKey";
import { useIdleTimer } from "react-idle-timer";
import TitleHeader from "./TitleHeader";
import { FormGroup } from "../Styles/DynamicKeyFormStyle/FormGroup"
import { ErrorFront } from "../Styles/DynamicKeyFormStyle/ErrorFront"
import usePageLoadEffect from "../MyHooks/usePageLoadEffect"
import { customerFlow } from "../Services/CustomerFlow";
import { ButtonGroupDynamicKey } from "./CustomButtons/ButtonGroupDynamicKey/ButtonGroupDynamicKey"
import { AlertGoOut } from "./AlertGoOut/AlertGoOut"
import { HandlerDynamicKeyForm } from "../HandlerComponents/HandlerDynamicKeyForm/HandlerDynamicKeyForm"
import { useHistory } from "react-router";
import HeaderTemplate from "./HeaderTemplate/HeaderTemplate"
import logoHeader from "../Images/headerIconBancolombia.svg";
import { contextSwitchHandlerBiocatch, back } from "../Utilities/GeneralFunctions"
import { loadFormDynamicKeyDatalayer, clickValidateFormDynamicKeyDatalayer } from "../HandlerComponents/HandlerDatalayers/HandlerDatalayers"
import { ContainerElement } from "../Styles/DynamicKeyFormStyle/ContainerElement"

export function DynamicKeyForm() {
  const [machine, send] = useMachineContext();
  const [error, setError] = useState("");
  const [activeButton, setActiveButton] = useState(false);
  const [values, setvalues] = useState();
  const [showAlert, setShowAlert] = useState(true);
  const [incorrectDynamicKey, setIncorrectDynamicKey] = useState(false)
  const [showPopUpGoOut, setShowPopUpGoOut] = useState(false);
  const history = useHistory();
  const isV2 = machine.context.startUrl.includes("v2")

  useEffect(() => {
    contextSwitchHandlerBiocatch("DYNAMIC_KEY_FORM")
    loadFormDynamicKeyDatalayer()
  }, []);

  useEffect(() => {
    customerFlow(machine.context, "0002", "FORM_DYNAMIC_KEY_03")
  }, []);

  const validateDynamicKeyFunction = React.useCallback(async () => {
    clickValidateFormDynamicKeyDatalayer()
    send("RETRYSENDDYNAMICKEY");
    send("VALIDATEDYNAMICKEYSF", { otp: values });
  });

  const close = React.useCallback(() => {
    setShowAlert(false);
  });

  useEffect(() => {
    if (machine.matches("sendCredentials.validateDynamicKeySecondFactor.errorDynamicKeyIncorrect")) {
      setIncorrectDynamicKey(true)
      setActiveButton(false)
      setShowAlert(true)
    }
  }, [machine]);

  const handleOnIdle = () => {
    window.location.replace(machine.context.startUrl)
  };

  useIdleTimer({
    timeout: timeExpirationDynamicKey,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  usePageLoadEffect()

  const showPopUp = React.useCallback(() => {
    setShowPopUpGoOut(true)
  });

  return (
    <PrincipalContainer>
      <HeaderTemplate logoHeader={logoHeader} isV2={isV2} exitFunction={showPopUp} backFunction={back} marginBottom='45px' marginBottomMobile='45px' />
      {showPopUpGoOut && <AlertGoOut machine={machine} setShowPopUpGoOut={setShowPopUpGoOut} isButtonBack={false} isV2={isV2} />}
      <MainContent>
        <TitleHeader title="Clave dinámica" />
        <Container>
          <ContainerHeader
            icon
            heightL="10%"
            subTitle={"Genérala desde tu App Bancolombia o ingresa el código de seguridad que hemos enviado como SMS."}
          />
          <FormGroup id="formGroup">
            <ContainerElement>
              <OtpInputDynamicKey
                setvalues={setvalues}
                error={error}
                setError={setError}
                setActiveButton={setActiveButton}
                setIncorrectDynamicKey={setIncorrectDynamicKey}
                incorrectDynamicKey={incorrectDynamicKey}
              />
              <ErrorFront error={error}>{error}</ErrorFront>
            </ContainerElement>
            <ButtonGroupDynamicKey machine={machine} activeButton={activeButton} validateForm={validateDynamicKeyFunction} />
          </FormGroup>
          <HandlerDynamicKeyForm machine={machine} history={history} />
        </Container>
        {machine.matches("sendCredentials.validateDynamicKeySecondFactor.errorDynamicKeyIncorrect") && showAlert && (
          <AlertMessageDynamicKey close={close} customMessage={"La Clave Dinámica es inválida. Inténtalo nuevamente."} />
        )}
      </MainContent>
      <Footer />
    </PrincipalContainer>
  );
}