import React, { useState } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { UseFua } from "../../Context/index";
import Container from "../Container";
import ContainerHeader from "../ContainerHeader";
import MainContent from "../MainContent";
import PrincipalContainer from "../PrincipalContainer";
import { useMachineContext } from "../../Context/contextMachine";
import { setModulus } from "../../Services/bcOaep";
import Input from "../Input";
import Footer from "../Footer";
import Modal from "../Modal";
import RememberUser from "../RememberUser";
import useModal from "../../MyHooks/useModal";
import Loader from "../Loader";
import TitleHeader from "../TitleHeader";
import { ErrorFront } from "../../Styles/UserFormStyle/ErrorFront"
import { FormGroup } from "../../Styles/UserFormStyle/FormGroup"
import { ForgotUser } from "../../Styles/UserFormStyle/ForgotUser"
import { validationSchema } from "../../Utilities/Regex"
import iconUser from "../../Images/user.svg";
import { ButtonGroupUserDynamicKeyForm } from "../CustomButtons/ButtonGroupUserDynamicKeyForm/ButtonGroupUserDynamicKeyForm"
import { AlertGoOut } from "../AlertGoOut/AlertGoOut"
import HeaderTemplate from "../HeaderTemplate/HeaderTemplate"
import logoHeader from "../../Images/headerIconBancolombia.svg";
import { HandlerUserDynamicKeyForm } from "../../HandlerComponents/HandlerUserForm/HandlerUserDynamicKeyForm"
import { ContainerElement } from "../../Styles/UserFormStyle/ContainerElement"
import { back } from "../../Utilities/GeneralFunctions"

export function UserFormDynamicKey() {
  const history = useHistory();
  const { setUsername, setDevice } = UseFua();
  const [machine, send] = useMachineContext();
  const isMatch = ["validateParameters", "validateCookie", "validCookie", "successSSO", "redirect"]
  const { isShowing, toggle } = useModal();
  const isMatchLoader = ["successSSO", "redirect", "validateUrl", "validateParameters", "validateRedirect", "validateCookie", "validCookie", "sendCredentials.validateRecaptcha", "validateUserDynamicKey.validateRecaptcha", "validateAlphanumericKeyAndTokenSf.validateRecaptcha"]
  const [showPopUpGoOut, setShowPopUpGoOut] = useState(false);
  const [isButtonBack, setIsButtonBack] = useState(false);

  const showPopUp = React.useCallback(() => {
    setShowPopUpGoOut(true)
    setIsButtonBack(false)
  });

  return (
    <PrincipalContainer>
      <HeaderTemplate logoHeader={logoHeader} isV2={true} exitFunction={showPopUp} backFunction={back} marginBottom='45px' marginBottomMobile='45px' />
      {showPopUpGoOut && <AlertGoOut machine={machine} setShowPopUpGoOut={setShowPopUpGoOut} isButtonBack={isButtonBack} isV2={true} />}
      <MainContent>
        <TitleHeader title="Te damos la bienvenida" />
        {(isMatchLoader.some(machine.matches)) && (<Loader />)}
        <HandlerUserDynamicKeyForm machine={machine} send={send} />
        <Container>
          <ContainerHeader
            subTitle="El usuario es el mismo con el que ingresas a la"
            otherDescription={"Sucursal Virtual Personas."}
            icon={false}
          />
          <Formik
            initialValues={{
              user: "",
            }}
            validationSchema={validationSchema}
            onSubmit={values => {
              send("RETRY", { username: values.user });
              setUsername(values.user);
              setModulus(machine.context.modulus);
              setDevice();
            }}
          >
            {({ errors, touched, handleSubmit, isValid }) => (
              <>
                <FormGroup onSubmit={handleSubmit}>
                  <ContainerElement>
                    <Input
                      type="text"
                      name="user"
                      autoCorrect="off"
                      autoComplete="off"
                      placeholder="&nbsp;"
                      label="Usuario"
                      valid={touched.user && !errors.user}
                      error={touched.user && errors.user}
                      top="0px"
                      disabled={isMatch.some(machine.matches)}
                      icon={iconUser}
                    />
                    {errors.user && <ErrorFront>{errors.user}</ErrorFront>}

                    {!errors.user && (
                      <ForgotUser onClick={toggle}>¿Olvidaste tu usuario?</ForgotUser>
                    )}
                  </ContainerElement>
                  <ButtonGroupUserDynamicKeyForm errors={errors} isValid={isValid} isMatch={isMatch} machine={machine} setShowPopUpGoOut={setShowPopUpGoOut} setIsButtonBack={setIsButtonBack} isHeaderTemplate={true}></ButtonGroupUserDynamicKeyForm>
                </FormGroup>
              </>
            )}
          </Formik>
          {machine.matches("errorForm") && (() => { history.push("/unavailability"); })()}
          <Modal isShowing={isShowing} hide={toggle} height="850px" widthM="340px" buttonClose>
            <RememberUser hide={toggle} />
          </Modal>
        </Container>
      </MainContent>
      <Footer />
    </PrincipalContainer>
  );
}