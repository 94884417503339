import ButtonTemplate from "../../ButtonTemplate/ButtonTemplate";
import {
    ButtonEnableStyle,
    ButtonDisableStyle,
    ButtonBackStyle,
} from "../../ButtonTemplate/ButtonTemplateStyle";
import { ButtonGroup } from "../../../Styles/UserFormStyle/ButtonGroup"
import { useHistory, useLocation } from "react-router";
import { referrerMatchAppAuth } from "../../../Constant/Constants"
import React from "react";

export function ButtonGroupUserForm({ errors, isValid, isMatch, machine, setShowPopUpGoOut, setIsButtonBack, isHeaderTemplate }) {
    const history = useHistory();
    const location = useLocation();
    const replacePath = (path, target, replacement) => path.replace(target, replacement);

    const showPopUp =  React.useCallback(async () => {
        const isReferrerAppAuth = referrerMatchAppAuth
        setIsButtonBack(true)
        if (isReferrerAppAuth) {
            const path = location.pathname;
            location.pathname = replacePath(path, "authorize", "appAuth");
            history.push(`${location.pathname}${location.search}`)
        } else {
            setShowPopUpGoOut(true)
        }
    });
    return (
        <>
            {isHeaderTemplate &&
                <ButtonGroup>
                    <ButtonTemplate
                        customStyle={ButtonBackStyle}
                        type="button"
                        title={"Volver"}
                        fuctionButton={showPopUp}
                        id={"btn-volver-user"}
                    />
                    <ButtonTemplate
                        customStyle={
                            !errors.user && isValid && !isMatch.some(machine.matches)
                                ? ButtonEnableStyle
                                : ButtonDisableStyle
                        }
                        type="submit"
                        title={"Continuar"}
                        id={"btn-continuar-user"}
                    />
                </ButtonGroup>
            }
        </>
    );
}