import ButtonTemplate from "../../ButtonTemplate/ButtonTemplate";
import {
    ButtonEnableStyle,
    ButtonDisableStyle,
    ButtonBackStyle,
} from "../../ButtonTemplate/ButtonTemplateStyle";
import { ButtonGroup } from "../../../Styles/PasswordFormStyle/ButtonGroup";
import { clickBackFormPasswordDatalayer } from "../../../HandlerComponents/HandlerDatalayers/HandlerDatalayers"
import React from "react";

export function ButtonGroupPasswordForm({ conditionReCaptchaToken, validateForm }) {
    const back = React.useCallback(() => {
        window.history.back()
        clickBackFormPasswordDatalayer()
    });

    return (
        <ButtonGroup>
            <ButtonTemplate
                customStyle={ButtonBackStyle}
                type="button"
                title={"Volver"}
                fuctionButton={back}
                id={"btn-volver-password"}
            />
            <ButtonTemplate
                customStyle={
                    conditionReCaptchaToken ? ButtonEnableStyle : ButtonDisableStyle
                }
                type="submit"
                fuctionButton={validateForm}
                title={"Continuar"}
                disabled={conditionReCaptchaToken ? false : true}
                id={"btn-continuar-password"}
            />
        </ButtonGroup>
    );
}
