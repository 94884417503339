import styled from "styled-components";

const PrincipalContainer = styled.div`
    height: 95vh;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0fr 2fr 0.3fr;

    @media screen and (max-width: 600px) {
        max-height: 800px;
    }
`;
export default PrincipalContainer;
