import Modal from "../../Components/Modal";
import ErrorDynamicKey from "../../Components/ErrorDynamicKey";
import RedirectionMessage from "../../Components/RedirectionMessage";

export function HandlerDynamicKeyForm({ machine, history }) {
    const isMatchUnavailability = ["sendCredentials.errorForm", "sendCredentials.errorPrepareDynamicKey", "sendCredentials.validateDynamicKeySecondFactor.errorService", "sendCredentials.validateDynamicKeySecondFactor.errorUnavailability"]
    return (
        <div>
            {(isMatchUnavailability.some(machine.matches)) && (() => { history.push("/unavailability"); })()}
            {machine.matches("sendCredentials.validateDynamicKeySecondFactor.errorDynamicKey") && (
                <Modal
                    isShowing
                    height="720px"
                    topM="0%"
                    top="0%"
                    buttonClose={false}
                    width="430px"
                    widthM="340px"
                >
                    <ErrorDynamicKey codeError={machine.context.errorCode} />
                </Modal>
            )}
            {machine.matches("sendCredentials.validateDynamicKeySecondFactor.successDynamicKey") && <RedirectionMessage />}
        </div>
    )
}