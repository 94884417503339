import AlertMessage from "../../Components/AlertMessage";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    EFA002,
    timeOutCustomerFlow,
    passwordFormPathv2,
    referrerMatchAppAuth
} from "../../Constant/Constants";
import getUrlParam, { getTrace, getState } from "../../Utilities/httpUtils";
import { contextSwitchHandlerBiocatch } from "../../Utilities/GeneralFunctions"
import { customerFlow } from "../../Services/CustomerFlow";
import {
    loadFormUserDatalayer,
    clickContinueFormUserDatalayer,
    loadFormAppPerson,
    loadFormUserDynamicKeyDatalayer,
    clickContinueFormAppPersonDatalayer,
    clickContinueFormUserDynamicKeyDatalayer,
    pushDatalayerClientIdUserPin,
    pushDatalayerClientIdAppPerson,
    pushDatalayerClientIdUserPinDynamicKey
} from "../../HandlerComponents/HandlerDatalayers/HandlerDatalayers"
import { flowNameUser, flowNameDynamicKey } from "../../Constant/ConstantsDataLayer"
import { useInterval } from 'react-use';

export function HandlerUserFormV2({ machine, send }) {
    const isMatchCredentialsError = ["sendCredentials.error", "validateUserDynamicKey.errorIncorrectData", "validateAlphanumericKeyAndTokenSf.errorIncorrectData"]
    const isMatchForm = ["sendCredentials.continueFlow", "validateUserDynamicKey.continueFlow", "validateAlphanumericKeyAndTokenSf.continueFlow"]
    const history = useHistory();
    const [isDataIncorrect, setIsDataIncorrect] = useState(false);
    const [showAlert, setShowAlert] = useState(true);
    const prompt = getUrlParam("prompt")
    const [isFlowUsCd, setIsFlowUsCd] = useState(prompt != null && prompt.includes("PN") && prompt.includes("CD"));

    useEffect(() => {
        setIsFlowUsCd(prompt != null && prompt.includes("PN") && prompt.includes("CD"))
    }, [machine]);

    useEffect(() => {
        contextSwitchHandlerBiocatch("USER_FORM_V2")
    }, []);

    useEffect(() => {
        if (referrerMatchAppAuth !== null) {
            pushDatalayerClientIdAppPerson()
            loadFormAppPerson()
        } else {
            if (isFlowUsCd) {
                pushDatalayerClientIdUserPinDynamicKey()
                loadFormUserDynamicKeyDatalayer()
            } else {
                pushDatalayerClientIdUserPin();
                loadFormUserDatalayer()
            }
        }
    }, [isFlowUsCd]);

    useEffect(() => {
        let operation = "0001"
        let step = "FORM_USER_01"
        if (referrerMatchAppAuth) {
            operation = "0002"
            step = "FORM_USER_02"
        }
        customerFlow(machine.context, operation, step)
    }, []);

    useEffect(() => {
        const errorTag = document.getElementById("errorAlert");
        if (errorTag) {
            errorTag.scrollIntoView(true);
            window.scrollTo(0, 0);
        }
    }, []);

    useInterval(() => {
        window.location.replace(machine.context.redirectUri + EFA002 + getTrace(machine.context) + getState(machine.context));
    }, timeOutCustomerFlow);

    const handleMatchForm = () => {
        history.push(passwordFormPathv2);
        executeDatalayers()
    };

    const handleMatchCredentialsError = () => {
        const hasIncorrectData = showAlert && isMatchCredentialsError.some(machine.matches);
        setIsDataIncorrect(hasIncorrectData);
    };

    useEffect(() => {
        if (isMatchForm.some(machine.matches)) {
            handleMatchForm();
        } else {
            handleMatchCredentialsError();
        }
    }, [machine, showAlert]);

    const close = () => {
        setShowAlert(false);
        send("RETRY");
    };

    const executeDatalayers = () => {
        if (referrerMatchAppAuth !== null) {
            clickContinueFormAppPersonDatalayer()
        } else {
            isFlowUsCd ? clickContinueFormUserDynamicKeyDatalayer() : clickContinueFormUserDatalayer()
        }
    }

    return (
        <>
            {isDataIncorrect && (
                <AlertMessage close={close} title="Datos incorrectos" message="Los datos ingresados no coinciden, inténtalo nuevamente." flowName={isFlowUsCd ? flowNameDynamicKey : flowNameUser} />
            )}
        </>
    )
}