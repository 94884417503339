import React, { useEffect } from "react";
import { SecondaryContainer } from "../Styles/UnavailabilityScreenStyle/SecondaryContainer";
import PrincipalContainer from "../Styles/UnavailabilityScreenStyle/PrincipalContainer";
import { useMachineContext } from "../Context/contextMachine";
import Footer from "./Footer";
import { LogoBank } from "../Styles/UserFormStyle/LogoBank"
import { Image } from "../Styles/UnavailabilityScreenStyle/Image"
import { Title } from "../Styles/UnavailabilityScreenStyle/Title"
import { Description } from "../Styles/UnavailabilityScreenStyle/Description"
import { Button } from "../Styles/UnavailabilityScreenStyle/Button"
import {
  BOTON_BANCOLOMBIA_BTN,
  redirectionUrlHomeBank,
  TIME_OUT_REDIRETION,
  EFA001,
  consumers,
  unknowErrror,
  userFormPath,
  appHandFormPath,
  dynamicKeySignaturePathV2,
  authDynamicKeyPath
} from "../Constant/Constants";
import { listUrlRedirect, listClientId } from "../Constant/ConstantsUrlRedirect";
import { 
  errorSystem, errorInvalidData, errorInvalidDataDescription, errorSystemDescription,
  flowNameUser, flowNameAuthOtp, flowNameDynamicKeyFirstFactor, flowNameDynamicKey
 } from "../Constant/ConstantsDataLayer"
import { getTrace, getState } from '../Utilities/httpUtils'
import config from "../config";
import { contextSwitchHandlerBiocatch } from "../Utilities/GeneralFunctions"
import { errorModalUnavailability } from "../HandlerComponents/HandlerDatalayers/HandlerDatalayers"

export function UnavailabilityScreen() {
  const [machine] = useMachineContext();
  const title = machine.context.errorCode === unknowErrror ? errorInvalidData : errorSystem;
  const description = machine.context.errorCode === unknowErrror ? errorInvalidDataDescription : errorSystemDescription;
  const redirection = machine.context.redirectUri + EFA001 + getTrace(machine.context) + getState(machine.context);
  const clientId = machine.context.idClient;
  const domain = config.domain.ENVIRONMENT_DOMAIN
  const validateErrors = machine.context.errorCode !== "9998" && machine.context.errorCode !== unknowErrror;

  useEffect(() => {
    contextSwitchHandlerBiocatch("UNAVAILABILITY_SCREEN")
  }, []);

  useEffect(() => {
    const errorTag = document.getElementById("errorAlert");
    if (errorTag) {
      errorTag.scrollIntoView(true);
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    try {
      const url = machine.context.startUrl
      const prompt = machine.context.prompt
      if (url && url !== "") {
        if (prompt !== null && (prompt.includes("PN|CD") || (prompt.includes("PN") && prompt.includes("CD")) )) {
          errorModalUnavailability(flowNameDynamicKey)
        } else if (url.includes(userFormPath) || url.includes(userFormPath.substring(0, userFormPath.length - 1))) {
          errorModalUnavailability(flowNameUser)
        } else if (url.includes(appHandFormPath) || url.includes(appHandFormPath.substring(0, appHandFormPath.length - 1))) {
          errorModalUnavailability(flowNameAuthOtp)
        } else if (url.includes(dynamicKeySignaturePathV2) || url.includes(dynamicKeySignaturePathV2.substring(0, dynamicKeySignaturePathV2.length - 1))) {
          errorModalUnavailability(flowNameDynamicKeyFirstFactor)
        } else if (url.includes(authDynamicKeyPath) || url.includes(authDynamicKeyPath.substring(0, authDynamicKeyPath.length - 1))) {
          errorModalUnavailability(flowNameDynamicKeyFirstFactor)
        }
      }
      return `success datalayer `
    } catch (e) {
      return `error datalayer ${e}`
    }
  }, [machine]);

  if ((machine.context.errorCode !== unknowErrror && consumers.includes(clientId)) || (clientId === BOTON_BANCOLOMBIA_BTN && validateErrors)) {
    setTimeout(() => {
      window.location.replace(redirection);
    }, TIME_OUT_REDIRETION);
  }

  const redirectionUri = e => {
    e.preventDefault();
    if (machine.context.errorCode === unknowErrror) {
      replaceUrl(machine.context.startUrl);
    } else if (consumers.includes(clientId)) {
      replaceUrl(redirection);
    } else if (listClientId.includes(clientId)) {
      let url = redirectionUrlHomeBank
      listUrlRedirect.forEach(key => {
        if (key.clientId === clientId) {
          switch (domain) {
            case "dev":
              url = key.urlRedirectDev
              break;
            case "qa":
              url = key.urlRedirectQa
              break;
            case "pdn":
              url = key.urlRedirectPdn
              break;
            default:
              break;
          }
        }
      });
      replaceUrl(url);
    } else {
      replaceUrl(redirectionUrlHomeBank);
    }
  };

  const replaceUrl = url => {
    window.location.replace(url);
  }

  return (
    <PrincipalContainer>
      <LogoBank id="errorAlert" show={true} />
      <SecondaryContainer>
        <Image />
        <Title>{title}</Title>
        <Description>{description}</Description>
        <Button onClick={redirectionUri}>Regresar</Button>
      </SecondaryContainer>
      <Footer top="0" topMovil="560px" />
    </PrincipalContainer>
  );
}
