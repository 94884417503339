import ModalErrorTemplate from "../ModalErrorTemplate/ModalErrorTemplate"
import alertIcon from "../../Images/alertIcon.svg"
import {ButtonGroupPopUpGoOut} from "../CustomButtons/ButtonGroupPopUpGoOut/ButtonGroupPopUpGoOut"
import React from "react";

export function AlertGoOut({ machine, setShowPopUpGoOut, isButtonBack, isV2 }) {
    const hidePopUpHandleCallback = React.useCallback(() => setShowPopUpGoOut(false))
    return (
        <ModalErrorTemplate additionalButtons={<ButtonGroupPopUpGoOut setShowPopUpGoOut={setShowPopUpGoOut} machine={machine} isButtonBack={isButtonBack} isV2={isV2}></ButtonGroupPopUpGoOut>} heightM={"280px"} widthM={"400px"} fuctionButtonClose={hidePopUpHandleCallback} isShowCloseButton={true} iconImageSvg={alertIcon} title={"¿Deseas salir?"} description={"No quedarán guardados los datos ingresados."} showAdditionalbotones={true} setShowPopUpGoOut={setShowPopUpGoOut} machine={machine} heightIcon={"48px"}></ModalErrorTemplate>
    );
}