import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { UseFua } from "../../Context/index";
import Container from "../Container";
import ContainerHeader from "../ContainerHeader";
import MainContent from "../MainContent";
import PrincipalContainer from "../PrincipalContainer";
import { useMachineContext } from "../../Context/contextMachine";
import { setModulus } from "../../Services/bcOaep";
import Input from "../Input";
import Footer from "../Footer";
import Modal from "../Modal";
import RememberUser from "../RememberUser";
import useModal from "../../MyHooks/useModal";
import Loader from "../Loader";
import getUrlParam, { getTrace, getState } from "../../Utilities/httpUtils";
import {
  EFA002,
  consumers,
  timeOutCustomerFlow,
  passwordFormPath,
  referrerMatchAppAuth
} from "../../Constant/Constants";
import TitleHeader from "../TitleHeader";
import { ErrorFront } from "../../Styles/UserFormStyle/ErrorFront"
import { FormGroup } from "../../Styles/UserFormStyle/FormGroup"
import { ForgotUser } from "../../Styles/UserFormStyle/ForgotUser"
import { customerFlow } from "../../Services/CustomerFlow";
import { validationSchema } from "../../Utilities/Regex"
import iconUser from "../../Images/user.svg";
import { ButtonGroupUserForm } from "../CustomButtons/ButtonGroupUserForm/ButtonGroupUserForm"
import { AlertGoOut } from "../AlertGoOut/AlertGoOut"
import HeaderTemplate from "../HeaderTemplate/HeaderTemplate"
import logoHeader from "../../Images/headerIconBancolombia.svg";
import { HandlerUserForm } from "../../HandlerComponents/HandlerUserForm/HandlerUserForm"
import { contextSwitchHandlerBiocatch, back } from "../../Utilities/GeneralFunctions"
import { loadFormUserDatalayer, loadFormUserDynamicKeyDatalayer, 
  loadFormAppPerson, pushDatalayerClientIdUserPin, 
  pushDatalayerClientIdAppPerson, pushDatalayerClientIdUserPinDynamicKey } from "../../HandlerComponents/HandlerDatalayers/HandlerDatalayers"
import { ContainerElement } from "../../Styles/UserFormStyle/ContainerElement"
import { useInterval } from 'react-use';

export function UserForm() {
  const history = useHistory();
  const { setUsername, setDevice } = UseFua();
  const [machine, send] = useMachineContext();
  const [showAlert, setShowAlert] = useState(true);
  const isMatch = ["validateParameters", "validateCookie", "validCookie", "successSSO", "redirect"]
  const { isShowing, toggle } = useModal();
  const isMatchLoader = ["successSSO", "redirect", "validateUrl", "validateParameters", "validateRedirect", "validateCookie", "validCookie", "sendCredentials.validateRecaptcha", "validateUserDynamicKey.validateRecaptcha", "validateAlphanumericKeyAndTokenSf.validateRecaptcha"]
  const [showPopUpGoOut, setShowPopUpGoOut] = useState(false);
  const [isButtonBack, setIsButtonBack] = useState(false);
  const prompt = getUrlParam("prompt")

  useEffect(() => {
    contextSwitchHandlerBiocatch("USER_FORM")
    executeInitialDatalayers()
  }, []);

  useEffect(() => {
    const errorTag = document.getElementById("errorAlert");
    if (errorTag) {
      errorTag.scrollIntoView(true);
      window.scrollTo(0, 0);
    }
  }, []);

  useInterval(() => {
    if (consumers.includes(machine.context.idClient)) {
      window.location.replace(machine.context.redirectUri + EFA002 + getTrace(machine.context) + getState(machine.context));
    }
  }, timeOutCustomerFlow);

  const close = React.useCallback(() => {
    setShowAlert(false);
    send("RETRY");
  });

  useEffect(() => {
    let operation = "0001"
    let step = "FORM_USER_01"
    if (referrerMatchAppAuth) {
      operation = "0002"
      step = "FORM_USER_02"
    }
    customerFlow(machine.context, operation, step)
  }, []);

  const showPopUp = React.useCallback(() => {
    setShowPopUpGoOut(true)
    setIsButtonBack(false)
  });

  const executeInitialDatalayers = () => {
    if (referrerMatchAppAuth !== null) {
      pushDatalayerClientIdAppPerson()
      loadFormAppPerson()
    } else {
      if(prompt != null && prompt.includes("PN|CD")){
        pushDatalayerClientIdUserPinDynamicKey()
        loadFormUserDynamicKeyDatalayer()
      }else{
        pushDatalayerClientIdUserPin();
        loadFormUserDatalayer()
      }
    }
  }

  return (
    <PrincipalContainer>
      <HeaderTemplate logoHeader={logoHeader} isV2={false} exitFunction={showPopUp} backFunction={back} marginBottom='45px' marginBottomMobile='45px' />
      {showPopUpGoOut && <AlertGoOut machine={machine} setShowPopUpGoOut={setShowPopUpGoOut} isButtonBack={isButtonBack} isV2={false} />}
      <MainContent>
        <TitleHeader title="Te damos la bienvenida" />
        {(isMatchLoader.some(machine.matches)) && (<Loader />)}
        <HandlerUserForm machine={machine} showAlert={showAlert} close={close} urlRedirect={passwordFormPath} />
        <Container>
          <ContainerHeader
            subTitle="El usuario es el mismo con el que ingresas a la"
            otherDescription={"Sucursal Virtual Personas."}
            icon={false}
          />
          <Formik
            initialValues={{
              user: "",
            }}
            validationSchema={validationSchema}
            onSubmit={values => {
              send("RETRY", { username: values.user });
              setUsername(values.user);
              setModulus(machine.context.modulus);
              setDevice();
            }}
          >
            {({ errors, touched, handleSubmit, isValid }) => (
              <>
                <FormGroup onSubmit={handleSubmit}>
                  <ContainerElement>
                    <Input
                      type="text"
                      name="user"
                      autoCorrect="off"
                      autoComplete="off"
                      placeholder="&nbsp;"
                      label="Usuario"
                      valid={touched.user && !errors.user}
                      error={touched.user && errors.user}
                      top="0px"
                      disabled={isMatch.some(machine.matches)}
                      icon={iconUser}
                    />
                    {errors.user && <ErrorFront>{errors.user}</ErrorFront>}
                    {!errors.user && (
                      <ForgotUser onClick={toggle}>¿Olvidaste tu usuario?</ForgotUser>
                    )}
                  </ContainerElement>
                  <ButtonGroupUserForm errors={errors} isValid={isValid} isMatch={isMatch} machine={machine} setShowPopUpGoOut={setShowPopUpGoOut} setIsButtonBack={setIsButtonBack} isHeaderTemplate={true}></ButtonGroupUserForm>
                </FormGroup>
              </>
            )}
          </Formik>
          {machine.matches("errorForm") && (() => { history.push("/unavailability"); })()}
          <Modal isShowing={isShowing} hide={toggle} height="850px" widthM="340px" buttonClose>
            <RememberUser hide={toggle} />
          </Modal>
        </Container>
      </MainContent>
      <Footer />
    </PrincipalContainer>
  );
}